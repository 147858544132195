import { ref } from 'vue'
import { useStore } from 'vuex'
import {alertController} from "@ionic/vue";
import {BASE_URL} from "@/composables/url_config";

const noPhotoProfileThumb="NoPhotoProfile.png"
// const DEBUG=true
const DEBUG=false

const log=(msg)=>{
    if(DEBUG) {
        console.log(msg)
    }
}

const getImgUrl= ()=> {
    const user=ref(null)
    const userAvatar=ref(null)
    const store = useStore()
    user.value= store.getters.getUser
    if(user.value) userAvatar.value=user.value.avatar
    if (userAvatar.value!=null){
        log("am gasit userAvatar.value not null")
        return userAvatar.value
    }
    else{
        log("am gasit userAvatar.value null, afisam noPhotoProfileThumb")
        let images = require.context('../assets/', false)
        return images('./' + noPhotoProfileThumb)
    }
}

const getBrandIconUrl=(img)=> {
     return (BASE_URL + '/brand_icons/' + img)
}

function formatDate(string_date) {
    let date=new Date(string_date)
    let diff = new Date() - date; // the difference in milliseconds
    if (diff < 30000) { // less than 1 second
        return 'right now';
    }
    let sec = Math.floor(diff / 1000); // convert diff to seconds
    if (sec < 60) {
        return sec + ' sec. ago';
    }
    let min = Math.floor(diff / 60000); // convert diff to minutes
    if (min < 60) {
        return min + ' min. ago';
    }
    // format the date
    // add leading zeroes to single-digit day/month/hours/minutes
    let d = date;
    d = [
        '0' + d.getDate(),
        '0' + (d.getMonth() + 1),
        '' + d.getFullYear(),
        '0' + d.getHours(),
        '0' + d.getMinutes()
    ].map(component => component.slice(-2)); // take last 2 digits of every component
    // join the components into date
    return d.slice(0, 3).join('.') + ' ' + d.slice(3).join(':');
}

const presentAlertMessage = async (message) => {
    log(`welcome back with  with message: `+message)
    const alert = await alertController.create({
        header: message,
        cssClass: 'custom-alert',
        buttons: [
            {
                text: 'Ok',
                cssClass: 'alert-button-confirm',
                handler: () => {
                    log("am apasat ok")
                }
            }
        ],
    });
    await alert.present();
};

const setLocalImageUrl = (filename) => {
    let images = require.context('../assets/images/', false)
    return images('./' + filename)
}

export  {log, getImgUrl, setLocalImageUrl, formatDate, presentAlertMessage, getBrandIconUrl}
