<template>
  <div class="ion-padding">
    <ion-popover :is-open="languagePopoverMobileOpen" reference="trigger" side="bottom"
                 alignment="start">
      <div class="ion-padding">
        <div class="flex-row flex-start align-items-center cursorPointer" @click="changeLanguage('ro')">
          <div><img src="../assets/images/romanian.png" class="langMobileFlag" alt="logo ro flag"></div>
          <div class="left10">Romanian</div>
        </div>
        <div class="flex-row flex-start align-items-center" @click="changeLanguage('en')">
          <div><img src="../assets/images/english.png" class="langMobileFlag" alt="logo ro flag"></div>
          <div class="left10">English</div>
        </div>
        <div class="flex-row flex-start align-items-center" @click="changeLanguage('bg')">
          <div><img src="../assets/images/bulgarian.png" class="langMobileFlag" alt="logo ro flag"></div>
          <div class="left10">Bulgarian</div>
        </div>
        <div class="flex-row flex-start align-items-center" @click="changeLanguage('ge')">
          <div><img src="../assets/images/german.png" class="langMobileFlag" alt="logo ro flag"></div>
          <div class="left10">German</div>
        </div>
        <div class="flex-row flex-start align-items-center" @click="changeLanguage('fr')">
          <div><img src="../assets/images/french.png" class="langMobileFlag" alt="logo ro flag"></div>
          <div class="left10">French</div>
        </div>
        <div class="flex-row flex-start align-items-center" @click="changeLanguage('sp')">
          <div><img src="../assets/images/spanish.png" class="langMobileFlag" alt="logo ro flag"></div>
          <div class="left10">Spanish</div>
        </div>
        <div class="flex-row flex-start align-items-center" @click="changeLanguage('hu')">
          <div><img src="../assets/images/hungarian.png" class="langMobileFlag" alt="logo ro flag"></div>
          <div class="left10">Hungarian</div>
        </div>
        <div class="flex-row flex-start align-items-center" @click="changeLanguage('it')">
          <div><img src="../assets/images/italian.png" class="langMobileFlag" alt="logo ro flag"></div>
          <div class="left10">Italian</div>
        </div>
        <div class="flex-row flex-start align-items-center" @click="changeLanguage('tr')">
          <div><img src="../assets/images/turkish.png" class="langMobileFlag" alt="logo ro flag"></div>
          <div class="left10">Turkish</div>
        </div>
        <div v-if="false" class="flex-row flex-start align-items-center" @click="changeLanguage('cn')">
          <div><img src="../assets/images/chinese.png" class="langMobileFlag" alt="logo ro flag"></div>
          <div class="left10">Chinese</div>
        </div>
        <div v-if="true" class="flex-row flex-start align-items-center" @click="changeLanguage('cn')">
          <div><img src="../assets/images/russian.png" class="langMobileFlag" alt="logo ro flag"></div>
          <div class="left10">Russian</div>
        </div>

      </div>
    </ion-popover>
  </div>
</template>
<script>
import {
  IonPopover,
} from '@ionic/vue';
  import {
    changeLanguage,
    languagePopoverOpen,
    languagePopoverMobileOpen,
  } from "@/composables/useLanguages";

  export default {
    components: {
      IonPopover
    },
    setup() {
      return{
        changeLanguage,
        languagePopoverOpen,
        languagePopoverMobileOpen,
        // IonPopover
      }
    }
  }
</script>
<style>
.langMobileFlag{
  width: 30px;
  height: 30px;
}
</style>
