import { createRouter, createWebHistory } from '@ionic/vue-router';
import HomeViewM2M from "@/views/HomeViewM2M.vue";
const routes = [
  {
    path: '/',
    name: 'root',
    component: HomeViewM2M
  },
 {
    path: '/home/:page',
    name: 'home',
    component: HomeViewM2M
  },
    {
        path: '/:catchAll(.*)', // Catch-all route
        redirect: '/home' // Redirect to the existing route
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
