import { createStore } from 'vuex'
import { Storage } from "@ionic/storage";
import {log} from "@/composables/useLog"

const ionicStorage = new Storage();
ionicStorage.create();
export default createStore({
  state: {
    user:null,
    loggedIn:false,
    token:null,
    carBrand:null,
    carBrandModel:null,
    filterAvailable:false,
    filterNonStop:false,
    filterBookable:false,
    chargingPort:null,
    activeUserEvCar:null,
    userEvCars:null,
    selectedEvCar:null,
    selectedPort:null,
    selectedUser:null,
    selectedStation:null,
    selectedStationId:null,
    selectedStationType:null,
    selectedStationStatus:null,
    selectedPartner:null,
    selectedPartnerType:null,
    selectedPartnerCommission:null,
    selectedProvider:null,
    showSwitchMapListMenu:null,
    activePaymentToken:null,
    activePaymentPanMasked:null,
    currentView:null,
    lastTransaction:null,
    currentTransaction:null,
    redirectionFormToMobilPay:null,
    filterLowerPower:null,
    filterUpperPower:null,
    filterSocketTypeId:null,
    filterMaxPriceKwh:null,
    filterStationCurrentType:'',
    filterMinimumPowerValue:null,
    filterMaximumPowerValue:null,
    filterMaximumStartChargingPrice:null,
    filterMinimumPriceValue:null,
    filterMaximumPriceValue:null,
    filterDescription:null,
    goToSelectedStation:null,
    acType:null,
    skipAddPaymentMethod:false,
    updateStationsSignal:false,
    isSavedActive:false,
    makeFilterIconRedSignal:null,
    doNotAskThisSessionToOpenLocation:null,
    versionCode:null,
    versionName:null,
    fcmToken:null,
  },
  getters: {
      getLoggedIn: state => state.loggedIn,
      getToken: state => state.token,
      getUser: state => state.user,
      getCarBrand: state => state.carBrand,
      getCarBrandModel: state => state.carBrandModel,
      getFilterAvailable: state => state.filterAvailable,
      getFilterNonStop: state => state.filterNonStop,
      getFilterBookable: state => state.filterBookable,
      getChargingPort: state => state.chargingPort,
      getActiveUserEvCar: state => state.activeUserEvCar,
      getUserEvCars: state => state.userEvCars,
      getSelectedUser: state => state.selectedUser,
      getSelectedPort: state => state.selectedPort,
      getSelectedEvCar: state => state.selectedEvCar,
      getSelectedStation: state => state.selectedStation,
      selectedStationId: state => state.selectedStationId,
      getSelectedStationType: state => state.selectedStationType,
      getSelectedStationStatus: state => state.selectedStationStatus,
      getSelectedPartner: state => state.selectedPartner,
      getSelectedPartnerType: state => state.selectedPartnerType,
      getSelectedPartnerCommission: state => state.selectedPartnerCommission,
      getSelectedProvider: state => state.selectedProvider,
      getShowSwitchMapListMenu: state => state.showSwitchMapListMenu,
      getActivePaymentToken: state => state.activePaymentToken,
      getActivePaymentPanMasked: state => state.activePaymentPanMasked,
      getCurrentView: state => state.currentView,
      getLastTransaction: state => state.lastTransaction,
      getCurrentTransaction: state => state.currentTransaction,
      getRedirectionFormToMobilPay: state => state.redirectionFormToMobilPay,
      getFilterLowerPower: state => state.filterLowerPower,
      getFilterUpperPower: state => state.filterUpperPower,
      getFilterSocketTypeId: state => state.filterSocketTypeId,
      getFilterMaxPriceKwh: state => state.filterMaxPriceKwh,
      getFilterStationCurrentType: state => state.filterStationCurrentType,
      getFilterMinimumPowerValue: state => state.filterMinimumPowerValue,
      getFilterMaximumPowerValue: state => state.filterMaximumPowerValue,
      getFilterMaximumStartChargingPrice: state => state.filterMaximumStartChargingPrice,
      getFilterMinimumPriceValue: state => state.filterMinimumPriceValue,
      getFilterMaximumPriceValue: state => state.filterMaximumPriceValue,
      getFilterDescription: state => state.filterDescription,
      getGoToSelectedStation: state => state.goToSelectedStation,
      getAcType: state => state.acType,
      getSkipAddPaymentMethod: state => state.skipAddPaymentMethod,
      getUpdateStationsSignal: state => state.updateStationsSignal,
      getIsSavedActive: state => state.isSavedActive,
      getMakeFilterIconRedSignal: state => state.makeFilterIconRedSignal,
      getDoNotAskThisSessionToOpenLocation: state => state.doNotAskThisSessionToOpenLocation,
      getVersionCode: state => state.versionCode,
      getVersionName: state => state.versionName,
      getFcmToken: state => state.fcmToken,

    async asyncGetLoggedIn(state){
      // const myAwesomeValue = await ionicStorage.get('loggedIn')
      // if(myAwesomeValue != ''){
      //   state.loggedIn = myAwesomeValue;
      // }
      return state.loggedIn;
    },
    // async getToken1(state){
    //   const myAwesomeValue = await ionicStorage.get('token')
    //   if(myAwesomeValue != ''){
    //     state.token = myAwesomeValue;
    //   }
    //   return state.token;
    // },
    // async getUser1(state){
    //   const myAwesomeValue = await ionicStorage.get('user')
    //   if(myAwesomeValue != ''){
    //     state.user = myAwesomeValue;
    //   }
    //   return state.user;
    // },

  },
  mutations: {
    setMakeFilterIconRedSignal: (state, payload) => {
        // ionicStorage.set('makeFilterIconRedSignal', true)
      log("in store scriem makeFilterIconRedSignal :"+payload)
      state.makeFilterIconRedSignal = payload
    },
    setFilterMinimumPowerValue: (state, payload) => {
        // ionicStorage.set('filterMinimumPowerValue', true)
      log("in store scriem setFilterMinimumPowerValue :")
      state.filterMinimumPowerValue = payload
    },
    setAcType: (state, payload) => {
        // ionicStorage.set('filterMinimumPowerValue', true)
        log("in store scriem setAcType :"+payload)
        state.acType = payload
    },
    setFilterMaximumPowerValue: (state, payload) => {
        // ionicStorage.set('filterMaximumPowerValue', true)
      log("in store scriem setFilterMaximumPowerValue :"+payload)
        state.filterMaximumPowerValue = payload
    },
    setFilterMaximumStartChargingPrice: (state, payload) => {
        // ionicStorage.set('filterMaximumStartChargingPrice', true)
      log("in store scriem filterMaximumStartChargingPrice :"+payload)
        state.filterMaximumStartChargingPrice = payload
    },
    setFilterMinimumPriceValue: (state, payload) => {
        // ionicStorage.set('filterMinimumPriceValue', true)
      log("in store scriem setFilterMinimumPriceValue :"+payload)
        state.filterMinimumPriceValue = payload
    },
    setFilterMaximumPriceValue: (state, payload) => {
        // ionicStorage.set('filterMaximumPriceValue', true)
      log("in store scriem setFilterMaximumPriceValue :"+payload)
        state.filterMaximumPriceValue = payload
    },
    setFilterLowerPower: (state, payload) => {
        // ionicStorage.set('filterLowerPower', true)
      log("in store scriem setFilterLowerPower :"+payload)
        state.filterLowerPower = payload
    },
    setFilterUpperPower: (state, payload) => {
        // ionicStorage.set('filterUpperPower', true)
      log("in store scriem setFilterUpperPower :"+payload)
        state.filterUpperPower = payload
    },
    setFilterSocketTypeId: (state, payload) => {
        // ionicStorage.set('filterSocketTypeId', true)
      log("in store scriem setFilterSocketTypeId :"+payload)
        state.filterSocketTypeId = payload
    },
    setFilterStationCurrentType: (state, payload) => {
        // ionicStorage.set('filterStationCurrentType', true)
        log("in store scriem setFilterStationCurrentType :"+payload)
        state.filterStationCurrentType = payload
    },
    setFilterMaxPriceKwh: (state, payload) => {
        // ionicStorage.set('filterMaxPriceKwh', true)
        log("in store scriem filterMaxPriceKwh :"+payload)
        state.filterMaxPriceKwh = payload
    },
    setFilterDescription: (state, payload) => {
    // ionicStorage.set('filterDescription', true)
      log("in store scriem filterDescription :"+payload)
      state.filterDescription = payload
    },
    setLoggedIn: (state, payload) => {
        // ionicStorage.set('loggedIn', true)
        state.loggedIn = payload
    },
    setRedirectionFormToMobilPay: (state, payload) => {
        // ionicStorage.set('loggedIn', true)
        state.redirectionFormToMobilPay = payload
    },
    setLastTransaction: (state, payload) => {
        // ionicStorage.set('lastTransaction', payload)
        state.lastTransaction = payload
    },
    setCurrentTransaction: (state, payload) => {
        // ionicStorage.set('currentTransaction', payload)
      log("in store scriem currentTransaction :")
      log(payload)
        state.currentTransaction = payload
    },
    setToken: (state,payload) => {
        // ionicStorage.set('token', payload.token)
        state.token = payload.token
    },
    setUser: (state, payload) => {
      log("in store scriem setUser :"+ payload)
      // ionicStorage.set('user', payload.user)
      state.user=payload.user
    },
    setCarBrand: (state, carBrand) => {
      log("in store scriem setCarBrand :"+ carBrand)
      // ionicStorage.set('carBrand', carBrand)
      state.carBrand=carBrand
    },
    setCarBrandModel: (state, carBrandModel) => {
      log("in store scriem setCarBrandModel :"+ carBrandModel)
      // ionicStorage.set('user',carBrandModel)
      state.carBrandModel=carBrandModel
    },

    setChargingPort: (state, chargingPort) => {
      log("in store scriem setChargingPort :"+ chargingPort)
      // ionicStorage.set('chargingPort', chargingPort)
      state.chargingPort=chargingPort
    },
    setCurrentView: (state, currentView) => {
      log("in store scriem setCurrentView :"+ currentView)
      // ionicStorage.set('currentView', currentView)
      state.currentView=currentView
    },

    setFilterAvailable: (state, value) => {
      log("in store scriem setFilterAvailable :"+ value)
      // ionicStorage.set('filterAvailable', value)
      state.filterAvailable=value
    },
    setFilterNonStop: (state, value) => {
      log("in store scriem setFilterNonStop:"+ value)
      // // ionicStorage.set('filterNonStop', value)
      state.filterNonStop=value
    },
    setFilterBookable: (state, value) => {
      log("in store scriem setFilterBookable:"+ value)
      // ionicStorage.set('filterBookable', value)
      state.filterBookable=value
    },
    setActiveUserEvCar: (state, car) => {
      log("in store scriem activeUserEvCar:")
      log(car)
      // ionicStorage.set('activeUserEvCar',car)
      state.activeUserEvCar=car
    },
    setSelectedEvCar: (state, car) => {
      log("in store scriem setSelectedEvCar:")
      log(car)
      // ionicStorage.set('setSelectedEvCar',car)
      state.selectedEvCar=car
    },
    setUserEvCars: (state, userEvCars) => {
      log("in store scriem setUserEvCars:")
      log(userEvCars)
      // ionicStorage.set('userEvCars', userEvCars)
      state.userEvCars=userEvCars
    },
    setSelectedPort: (state, selectedPort) => {
      log("in store scriem setSelectedPort:"+ selectedPort)
      // ionicStorage.set('selectedPort', selectedPort)
      state.selectedPort=selectedPort
    },
    setSelectedUser: (state, selectedUser) => {
      log("in store scriem setSelectedUser:"+ selectedUser)
      // ionicStorage.set('selectedUser', selectedUser)
      state.selectedUser=selectedUser
    },
    setSelectedStation: (state, selectedStation) => {
      log("in store scriem setSelectedStation:"+ selectedStation)
      // ionicStorage.set('selectedStation', selectedStation)
      state.selectedStation=selectedStation
    },
    setSelectedStationId: (state, selectedStationId) => {
      log("in store scriem setSelectedStationId:"+ selectedStationId)
      // ionicStorage.set('selectedStation', selectedStation)
      state.selectedStationId=selectedStationId
    },
    setSelectedStationType: (state, selectedStationType) => {
      log("in store scriem setSelectedStationType:"+ selectedStationType)
      // ionicStorage.set('selectedStation', selectedStation)
      state.selectedStationType=selectedStationType
    },

    setSelectedStationStatus: (state, selectedStationStatus) => {
      log("in store scriem setSelectedStationStatus:"+ selectedStationStatus)
      // ionicStorage.set('selectedStation', selectedStation)
      state.selectedStationStatus=selectedStationStatus
    },

    setSelectedPartner: (state, selectedPartner) => {
      log("in store scriem setSelectedPartner:"+ selectedPartner)
      // ionicStorage.set('selectedPartner', selectedPartner)
      state.selectedPartner=selectedPartner
    },
    setSelectedPartnerType: (state, selectedPartnerType) => {
      log("in store scriem setSelectedPartnerType:"+ selectedPartnerType)
      // ionicStorage.set('selectedPartnerType', selectedPartnerType)
      state.selectedPartnerType=selectedPartnerType
    },
    setSelectedPartnerCommission: (state, selectedPartnerCommission) => {
      log("in store scriem setSelectedPartnerCommission:"+ selectedPartnerCommission)
      // ionicStorage.set('selectedPartnerCommission', selectedPartnerCommission)
      state.selectedPartnerCommission=selectedPartnerCommission
    },
    setSelectedProvider: (state, selectedProvider) => {
      log("in store scriem setSelectedProvider:"+ selectedProvider)
      // ionicStorage.set('selectedProvider', selectedProvider)
      state.selectedProvider=selectedProvider
    },

    setShowSwitchMapListMenu: (state, value) => {
      log("in store scriem setShowSwitchMapListMenu:"+ value)
      // ionicStorage.set('showSwitchMapListMenu', value)
      state.showSwitchMapListMenu=value
    },
    setActivePaymentToken: (state, value) => {
      log("in store scriem setActivePaymentToken:"+ value)
      // ionicStorage.set('activePaymentToken', value)
      state.activePaymentToken=value
    },
    setActivePaymentPanMasked: (state, value) => {
      log("in store scriem setActivePaymentPanMasked:"+ value)
      // ionicStorage.set('activePaymentPanMasked', value)
      state.activePaymentPanMasked=value
    },
    setGoToSelectedStation: (state, value) => {
      log("in store scriem goToSelectedStation:"+ value)
      // ionicStorage.set('activePaymentPanMasked', value)
      state.goToSelectedStation=value
    },
    setSkipAddPaymentMethod: (state, value) => {
      log("in store scriem skipAddPaymentMethod:"+ value)
      // ionicStorage.set('skipAddPaymentMethod', value)
      state.skipAddPaymentMethod=value
    },
    setUpdateStationsSignal: (state, value) => {
      log("in store scriem updateStationsSignal:"+ value)
      // ionicStorage.set('setSkipAddPaymentMethod', value)
      state.updateStationsSignal=value
    },
    setDoNotAskThisSessionToOpenLocation: (state, value) => {
      log("in store scriem doNotAskThisSessionToOpenLocation:"+ value)
      // ionicStorage.set('setSkipAddPaymentMethod', value)
      state.doNotAskThisSessionToOpenLocation=value
    },
    setIsSavedActive: (state, value) => {
      log("in store scriem isSavedActive:"+ value)
      // ionicStorage.set('setSkipAddPaymentMethod', value)
      state.isSavedActive=value
    },
     setVersionCode: (state, value) => {
      log("in store scriem versionCode:"+ value)
      // ionicStorage.set('versionCode', value)
      state.versionCode=value
    },
     setVersionName: (state, value) => {
      log("in store scriem versionCode:"+ value)
      // ionicStorage.set('versionName', value)
      state.versionName=value
    },
    setFcmToken: (state, value) => {
      log("in store scriem fcmToken:"+ value)
      // ionicStorage.set('setFcmToken', value)
      state.fcmToken=value
    },
    setLoggedOut: (state) => {
      state.user=null
      state.goToSelectedStation=null
      state.fcmToken=null
      state.acType=null
      // ionicStorage.set('user', null)
      state.loggedIn=false
      // ionicStorage.set('loggedIn', false)
      state.token=null
      // ionicStorage.set('token', null)
      state.carBrand=null
      // ionicStorage.set('carBrand',null)
      state.carBrandModel=null
      // ionicStorage.set('carBrandModel',null)
      state.filterAvailable=null
      // ionicStorage.set('filterAvailable',null)
      state.filterNonStop=null
      // ionicStorage.set('filterNonStop',null)
      state.filterBookable=null
      // ionicStorage.set('filterBookable',null)
      state.chargingPort=null
      // ionicStorage.set('chargingPort',null)
      state.activeUserEvCar=null
      // ionicStorage.set('activeUserEvCar',null)
      state.userEvCars=null
      // ionicStorage.set('userEvCars',null)
      state.activeUserEvCar=null
      // ionicStorage.set('activeUserEvCar',null)
      state.selectedPort=null
      // ionicStorage.set('selectedPort',null)
      state.selectedEvCar=null
      // ionicStorage.set('selectedEvCar',null)
      state.selectedUser=null
      // ionicStorage.set('selectedUser',null)
      state.showSwitchMapListMenu=null
      // ionicStorage.set('showSwitchMapListMenu',null)
      state.activePaymentToken=null
      // ionicStorage.set('activePaymentToken',null)
      state.activePaymentPanMasked=null
      // ionicStorage.set('activePaymentPanMasked',null)
      state.currentTransaction=null
      // ionicStorage.set('currentTransaction',null)
      state.lastTransaction=null
      // ionicStorage.set('lastTransaction',null)
      state.currentView=null
      state.redirectionFormToMobilPay=null
      state.selectedStation=null
      state.filterStationCurrentType=null
      state.filterMinimumPowerValue=null
      state.filterMaximumPowerValue=null
      state.filterMaximumStartChargingPrice=null
      state.filterMinimumPriceValue=null
      state.filterMaximumPriceValue=null
      state.filterLowerPower=null
      state.filterUpperPower=null
      state.filterSocketTypeId=null
      state.filterStationCurrentType=null
      state.filterAvailable=null
      state.filterNonStop=null
      state.filterBookable=null
      state.updateStationsSignal=false
    },
  },
  actions: {
    // log(1)

  },
  modules: {
  }
})

