<template>

<!--  </div>-->
  <ion-page>
    <ion-content class="ion-padding">
      <div  v-if="is_mobile && showMenu" style="position:relative;top:30px;width:80%;z-index: 1000">
          <ion-header   @click="goToHome">
            <ion-toolbar>
              <ion-title>MenuList</ion-title>
            </ion-toolbar>
          </ion-header>
          <div class="border0">
            <div @click="goToHome" class="left20 top20">{{ t.home }}</div>
          </div>
          <div class="border0">
            <div @click="goToHome" class="left20 top20">{{ t.buy }}</div>
          </div>
          <div class="border0">
            <div @click="goToAboutNextM2M" class="left20 top20">{{ t.menu_about_nextm2m }}  </div>
          </div>
          <div>
            <div @click="goToProductsAndServices" class="left20 top20"> {{t.menu_products_and_services}} </div>
          </div>
          <div>
            <div @click="goToRecharge" class="left20 top20" >{{t.menu_topup}}</div>
          </div>
          <div>
            <div @click="goToContact" class="left20 top20">{{t.contact}}</div>
          </div>
          <div>
            <div @click="openLanguageMobilePopover" class="flex flex-row align-items-center left20 top10">
              <div>
                {{t.languages}}: <span class="left20"></span>

              </div>
              <div> <img :src="setLocalImageUrl(t.flagPath)" class="" style="width:30px;" alt="logo ro flag"></div>
              <div><LanguagesPopover></LanguagesPopover></div>
            </div>
          </div>
          <div>
            <div @click="goToLegal" class="left20 top20">{{ t.legal }}</div>
          </div>
          <div>
            <div @click="closeMenu" class="left20 top20">{{ t.close }}</div>
          </div>
          <div>
            <div @click="login_under_construction" class="left20 top20  opacity30">MyAccount</div>
          </div>

      </div>
      <div v-if="showMainContent" class="  max1000 top30" style="margin: auto">
        <div v-if="is_mobile" name="menu_mobile_button">
          <ion-header>
            <ion-toolbar>
              <div class="flex-row  border0">
                <div id="divLeftContent" @click="goToHome" class="flex-column border0 align-items-start" style="">
                  <div id="divLogoSite" class="logoSiteMobile"></div>
                </div>
                <ion-title class="border0 ion-text-center">Next-M2M</ion-title>
              </div>
              <div slot="end">
                <div  class="right20 black" @click="openMaineMenu">
                  <ion-icon :icon="menuOutline" style="font-size: 26px;color:black"></ion-icon>
                </div>
              </div>

            </ion-toolbar>
          </ion-header>
        </div>
        <div v-if="showInfoCookies" class="blurBellowInfoCookies   padding_20  div_text_center" ></div>
        <div v-if="showInfoCookies" class="divInfoCookies  max800 padding_20  div_text_center"  style="position:fixed;top:250px;background-color: white;z-index:3;" :class="is_mobile?' padding:10px;top60':' width60 ion-text-center left50'">
            <div id="closeDivInfoCookies" class="div_text_right" @click="closeShowInfoCookies">
               <ion-icon :icon="closeCircleOutline" style="font-size: 26px;color:red"></ion-icon>
            </div>
            <div class="flex-row flex-justify-content-space-evenly align-items-center bottom10">
              <div v-if="!is_mobile" id="divLogoCookies" class="left20  padding_10 ">
                <div>
                  <img class="" src="../assets/images/logo_site.png" />
                </div>
              </div>
              <div>
                <h3>
                  Acest site utilizează cookie-uri
                </h3>
              </div>
            </div>
          <div>
            Folosim cookie-uri pentru a personaliza conținutul și anunțurile, pentru a oferi funcții de rețele sociale și pentru a analiza traficul. De asemenea, le oferim partenerilor de rețele sociale, de publicitate și de analize informații cu privire la modul în care folosiți site-ul nostru. Aceștia le pot combina cu alte informații oferite de dvs. sau culese în urma folosirii serviciilor lor. În cazul în care alegeți să continuați să utilizați website-ul nostru, sunteți de acord cu utilizarea modulelor noastre cookie.
            <div class="flex-row flex-justify-content-space-evenly align-items-center top20">
              <div>
                <a target="_blank" class="" href="./docs/document_benefito_de_informare_cookie.pdf">Politica de Cookies</a>
              </div>
              <div id="divBtnOkCookies">
                <button id="btnAcceptCookiesPolicy" @click="acceptCookies" class="btnBlackOnOrange">Ok, sunt de acord</button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showSpinner" class="mySpinner width100 text-center" style="position:fixed;top:35%;z-index: 10;left:35%;">
          <img class="width250px" src="../assets/images/spinner.gif" /></div>

        <div id="main_wrapper" class="flex-col flex-center align-items-center  width100 maxWidth1280 border0">
          <div name="menu desktop" v-if="!is_mobile">
            <div id="orange_bar" class="flex-row flex-justify-content-space-between align-items-center top30 barHeight max1000 border0 orange_bar" >
              <div id="orange_bar1" class="orange_bar flex8 barHeight"> </div>
              <div v-if="showTopMenuRecharge" class="flex1 div_text_center padding_lr_10 top5 blackLink fontBanda ">
                <div @click="goToRecharge" style="font-color:black;cursor: pointer;"> REÎNCĂRCARE </div>
              </div>
              <div id="orange_bar_flags" class="orange_bar  flex-row align-items-center flex-start  " style="height: 15px;">
                <div class="flex-row flex-start align-items-center top7 cursorPointer" @click="changeLanguage('ro')">
                  <div :class="roSelected?'selectedFlag':''"> <img src="../assets/images/romanian.png" class="langFlag" alt="logo ro flag"></div>
                </div>
                <div class="left20 flex-row flex-start align-items-center top7 cursorPointer" @click="changeLanguage('en')">
                  <div :class="enSelected?'selectedFlag':''"> <img src="../assets/images/english.png" class="langFlag" alt="logo en flag"></div>
                </div>
                <div class="left20 flex-row flex-start align-items-center top7 cursorPointer" @click="changeLanguage('bg')" style="border: 0px solid white;">
                  <div :class="bgSelected?'selectedFlag':''"> <img src="../assets/images/bulgarian.png" class="langFlag" alt="logo bg flag"></div>
                </div>
                <div class="left20 flex-row flex-start align-items-center top7 cursorPointer" @click="changeLanguage('ge')" style="border: 0px solid white;">
                  <div :class="geSelected?'selectedFlag':''"> <img src="../assets/images/german.png" class="langFlag" alt="logo ge flag"></div>
                </div>
                <div class="left20 flex-row flex-start align-items-center top7 cursorPointer" @click="changeLanguage('fr')">
                  <div :class="frSelected?'selectedFlag':''"> <img src="../assets/images/french.png" class="langFlag" alt="logo ro flag"></div>
                </div>
                <div class="left20 flex-row flex-start align-items-center top7 cursorPointer" @click="changeLanguage('sp')">
                  <div :class="spSelected?'selectedFlag':''"> <img src="../assets/images/spanish.png" class="langFlag" alt="logo ro flag"></div>
                </div>
                <div class="left20 flex-row flex-start align-items-center top7 cursorPointer" @click="changeLanguage('it')">
                  <div :class="itSelected?'selectedFlag':''"> <img src="../assets/images/italian.png" class="langFlag" alt="logo ro flag"></div>
                </div>
                <div v-if="false" class="left20 flex-row flex-start align-items-center top7 cursorPointer" @click="changeLanguage('tr')">
                  <div :class="trSelected?'selectedFlag':''"> <img src="../assets/images/turkish.png" class="langFlag" alt="logo ro flag"></div>
                </div>
                <div v-if="false" class="left20 flex-row flex-start align-items-center top7 cursorPointer" @click="changeLanguage('ru')">
                  <div :class="ruSelected?'selectedFlag':''"> <img src="../assets/images/russian.png" class="langFlag" alt="logo ro flag"></div>
                </div>
                <div v-if="false" class="left20 flex-row flex-start align-items-center top7 cursorPointer" @click="changeLanguage('cn')">
                  <div :class="cnSelected?'selectedFlag':''"> <img src="../assets/images/chinese.png" class="langFlag" alt="logo ro flag"></div>
                </div>

                <div v-if="true" id="language_icons" class="left10 orange_bar barHeight">
                  <div>
                    <div class="left20 font16 top5"  @click="openLanguagePopover()" id="openLanguagePopover"
                         v-if="
                       t.country_code=='ro' ||
                       t.country_code=='en' ||
                       t.country_code=='ge' ||
                       t.country_code=='it' ||
                       t.country_code=='sp' ||
                       t.country_code=='hu' ||
                       t.country_code=='fr' ||
                       t.country_code=='ru' ||
                       t.country_code=='bg' ||
                       t.country_code=='tr' ||
                       t.country_code=='cn'
">
                      <ion-icon :icon="refreshCircleOutline" class="border0" style="font-size: 20px;color:white"></ion-icon>
                    </div>
                  </div>
                  <div class="ion-padding">
                    <ion-popover  :is-open="languagePopoverOpen" reference="trigger"  side="bottom" alignment="start">
                      <ion-content class="ion-padding">
                        <div class="flex-row flex-start align-items-center cursorPointer" @click="changeLanguage('ro')">
                          <div> <img src="../assets/images/romanian.png" class="langFlag" alt="logo ro flag"></div>
                          <div class="left10">Romanian</div>
                        </div>
                        <div class="flex-row flex-start align-items-center" @click="changeLanguage('en')">
                          <div> <img src="../assets/images/english.png" class="langFlag" alt="logo ro flag"></div>
                          <div class="left10">English</div>
                        </div>
                        <div class="flex-row flex-start align-items-center" @click="changeLanguage('bg')">
                          <div> <img src="../assets/images/bulgarian.png" class="langFlag" alt="logo ro flag"></div>
                          <div class="left10">Bulgarian</div>
                        </div>
                        <div class="flex-row flex-start align-items-center" @click="changeLanguage('ge')">
                          <div> <img src="../assets/images/german.png" class="langFlag" alt="logo ro flag"></div>
                          <div class="left10">German</div>
                        </div>
                        <div class="flex-row flex-start align-items-center" @click="changeLanguage('fr')">
                          <div> <img src="../assets/images/french.png" class="langFlag" alt="logo ro flag"></div>
                          <div class="left10">French</div>
                        </div>
                        <div class="flex-row flex-start align-items-center" @click="changeLanguage('sp')">
                          <div> <img src="../assets/images/spanish.png" class="langFlag" alt="logo ro flag"></div>
                          <div class="left10">Spanish</div>
                        </div>
                        <div class="flex-row flex-start align-items-center" @click="changeLanguage('hu')">
                          <div> <img src="../assets/images/hungarian.png" class="langFlag" alt="logo ro flag"></div>
                          <div class="left10">Hungarian</div>
                        </div>
                        <div class="flex-row flex-start align-items-center" @click="changeLanguage('it')">
                          <div> <img src="../assets/images/italian.png" class="langFlag" alt="logo ro flag"></div>
                          <div class="left10">Italian</div>
                        </div>
                        <div class="flex-row flex-start align-items-center" @click="changeLanguage('tr')">
                          <div> <img src="../assets/images/turkish.png" class="langFlag" alt="logo ro flag"></div>
                          <div class="left10">Turkish</div>
                        </div>
                        <div v-if="false" class="flex-row flex-start align-items-center" @click="changeLanguage('cn')">
                          <div> <img src="../assets/images/chinese.png" class="langFlag" alt="logo ro flag"></div>
                          <div class="left10">Chinese</div>
                        </div>

                      </ion-content>
                    </ion-popover>
                  </div>
                </div>

              </div>

              <div class="div_text_center border0 padding_lr_10 orange_bar  text-right smaller1 barHeight" >
                <div class="top7">
                  <a @click="login_under_construction">  <div class="width100  smaller1" style="width:50px;height:25px" > Login</div> </a>
                </div>
              </div>
              <div id="orange_bar2" class="orange_bar hidden flex1 barHeight "></div>
            </div>
            <div class="black_bar flex-column align-items-center colorWhite barHeight max1000 ">
            <div  id="menuItems" class="flex-row flex-justify-content-center colorWhite whiteLink " :class="cnSelected?'top3':'top10'">
              <div>
                <div @click="goToHome" class="lnkHome colorWhite left20 cursorPointer" href="/"> {{ t.menu_buy }}    </div>
              </div>
             <div>
                <div @click="goToAboutNextM2M" class="lnkAboutNextM2M colorWhite left20 cursorPointer" href="#">  {{ t.menu_about_nextm2m }}      </div>
              </div>
              <div>
                <div @click="goToProductsAndServices" class="lnkTariffs colorWhite left20 cursorPointer" href="#"> {{ t.menu_products_and_services }}      </div>
              </div>
              <div>
                <div @click="goToRecharge" class="lnkHome colorWhite left20 cursorPointer" href="/">{{ t.menu_topup }}</div>
              </div>
              <div>
                <div  @click="goToContact" class="lnkContact colorWhite left20 cursorPointer" href="#">  {{ t.menu_contact }}   </div>
              </div>
            </div>
          </div>
          </div>
          <div id="divMainContent" class="flex-row flex-between maxWidth1280" >
            <div  id="divLeftContent" @click="goToHome" v-if="!is_mobile " class="flex-column   align-items-start" style="position:relative;top:-90px;">
              <div id="divLogoSite" class="logoSite cursorPointer"></div>
              <div id="divLogoVertical" class="logoVertical  div_text_center"></div>
            </div>
            <div  id="divRightContent" v-if="showMainSection" class="flex-column flex-justify-content-start width100 border0">
              <div v-if="showCeleMaiAccesibile" :class="is_mobile?'mainTitleMobile':'mainTitle'" class="border0 flex-column orangeTitle div_text_center top20 width100">
               {{t.band_the_most_affordable_prepaid_sims}}
              </div>
              <div v-if="show_section_recharge_mobile">
                  <div v-if="show_topup_voucher_mobile" class="border0 ion-padding">
                      <h4>{{t.do_ypu_have_a_recharging_code}}</h4>
                      <div class="flex-row flex-justify-content-start align-items-center  ">
                          <div id="topupLabelTelefon" class="">
                              {{t.telefon}}
                          </div>
                          <div id="topupFieldTelefon" class=" ">
                              <ion-input type="number" label="false" required="required" v-model="topup_voucher_front_page_msisdn"/>
                          </div>
                      </div>

                      <div id="wrapTopupCode" class="flex-row flex-justify-content-start align-items-center  ">
                          <div id="topupLabelCode" class="div_text_left flex1 ">
                              {{t.recharging_code}}:
                          </div>

                          <div id="topupFieldCode" class="div_text_center flex1">
                              <ion-input type="number" label="false" v-model="topup_voucher_front_page_voucher"  />
                          </div>
                      </div>

                      <div class="flex-row flex-around  ">
                          <div class=" ">
                            <button @click="goToHome" color="danger" @click.prevent="cancel_recharge_by_voucher">{{t.btn_cancel}}</button>
                          </div>
                          <div class=" ">
                              <ion-button color="none" class="btnComandaMic" @click.prevent="recharge_by_voucher">ÎNCARCĂ</ion-button>
                          </div>
                      </div>

                      <!--                        </form>-->
                  </div>
                  <div class="orange_bar_gradient width100"></div>
                  <div v-if="show_topup_cc_mobile" class="top10 left30 border0 ion-padding">
<!--                      <form action="/home/topup_voucher_front_page" accept-charset="UTF-8" data-remote="true" method="post"><input name="utf8" type="hidden" value="&#x2713;" />-->
                          <div id='topup_mobile' class="top10 border0  width100">
                                  <div class="subtitluCartelaNextM2m"> {{t.topup_nextm2m_account}}</div>
                                  <div class="flex-row flex-center align-items-center">
                                    <div>
                                      <img src="../assets/images/sigla_visa.png" class="siglaMobile" alt="logo BT">
                                    </div>
                                    <div class="left20">
                                      <img src="../assets/images/sigla_btpay.png" class="siglaMobile" style="opacity:0.5" alt="logo BT">
                                    </div>
                                    <div class="left20">
                                      <img src="../assets/images/sigla_mastercard.png" class="siglaMobile" alt="logo BT">
                                    </div>
                                  </div>

                              <div class="flex-row flex-justify-content-start align-items-center  ">
                                  <div id="topupLabelTelefon" class="">
                                      {{t.topup_phone_number}}
                                  </div>
                                  <div id="topupFieldTelefon" class=" ">
                                      <ion-input type="number" label="false" required="required" v-model="enteredMsisdn"/>
                                  </div>
                              </div>

                                <div id="wrapRechargeValues" class="flex-row flex-start top20 align-items-center ">
                                  <div class="flex1">
                                      {{t.topup_value_credit}}:
                                  </div>
                                  <div class="flex1">
                                    <ion-select v-model="selectedRechargeAmount" aria-label="fruit" placeholder="....valori disponibileeee">
                                        <ion-select-option :selected="true"  :value="recharging_values[5].value"><span style="font-size:28px;font-weight: bolder">{{recharging_values[5].value}} &euro;</span> {{t.validity}} {{recharging_values[5].validity}} {{t.months}} </ion-select-option>
                                        <ion-select-option :value=recharging_values[10].value><span style="font-size:20px">{{recharging_values[10].value}} &euro;</span> {{t.validity}} {{recharging_values[10].validity}} {{t.year}} </ion-select-option>
                                        <ion-select-option :value=recharging_values[15].value><span style="font-size:20px">{{recharging_values[15].value}} &euro;</span> {{t.validity}} {{recharging_values[15].validity}} {{t.years}} </ion-select-option>
                                        <ion-select-option :value=recharging_values[20].value><span style="font-size:20px">{{recharging_values[20].value}} &euro;</span> {{t.validity}} {{recharging_values[20].validity}} {{t.years}} </ion-select-option>
                                        <ion-select-option :value=recharging_values[25].value><span style="font-size:20px">{{recharging_values[25].value}} &euro;</span> {{t.validity}} {{recharging_values[25].validity}} {{t.years}} </ion-select-option>
                                        <ion-select-option :value=recharging_values[30].value><span style="font-size:20px">{{recharging_values[30].value}} &euro;</span> {{t.validity}} {{recharging_values[30].validity}} {{t.years}} </ion-select-option>

                                       </ion-select>
                                  </div>
                                </div>

                                <div class="flex-row flex-justify-content-start align-items-center  ">
                                  <div id="topupLabelTelefon" class="">
                                    {{t.topup_email}}:
                                  </div>
                                  <div id="topupFieldTelefon" class=" ">
                                    <ion-input type="email" label="false" required="required" v-model="enteredEmail"/>
                                  </div>
                                </div>

                                <div class="flex-row flex-around align-items-center top20 border0">
                                    <div class=" ">
                                        <ion-button  @click="goToHome" color="danger"  class="" >{{t.btn_cancel}} </ion-button>
                                    </div>
                                    <div class=" ">
                                        <ion-button  color="none" @click.prevent="confirm_recharge_by_cc" value="" class="btnComandaMic" >{{t.btn_buy}}</ion-button>
                                    </div>
                                </div>


                          </div>
<!--                      </form>-->
                  </div>
              </div>
              <div v-if="show_section_recharge_desktop">
                  <div v-if="show_topup_voucher_desktop" class="border0 ion-padding">
                      <h4>{{t.do_ypu_have_a_recharging_code}}</h4>
                      <div class="flex-row flex-justify-content-start align-items-center  ">
                          <div id="topupLabelTelefon" class="">
                             {{t.telefon}}
                          </div>
                          <div id="topupFieldTelefon" class=" ">
                              <ion-input type="number" label="false" required="required" v-model="topup_voucher_front_page_msisdn"/>
                          </div>
                      </div>

                      <div id="wrapTopupCode" class="flex-row flex-justify-content-start align-items-center  ">
                          <div id="topupLabelCode" class="div_text_left flex1 ">
                             {{t.recharging_code}}:
                          </div>

                          <div id="topupFieldCode" class="div_text_center flex1">
                              <ion-input type="number" label="false" v-model="topup_voucher_front_page_voucher"  />
                          </div>
                      </div>

                      <div class="flex-row flex-around top30 ">
                          <div class="">
                              <ion-button @click="goToHome" color="danger" @click.prevent="cancel_recharge_by_voucher">{{t.btn_cancel}} </ion-button>
                          </div>

                          <div class=" ">
                              <ion-button color="none" class="btnComandaMic" @click.prevent="recharge_by_voucher">{{t.topup}}</ion-button>
                          </div>
                      </div>

                      <!--                        </form>-->
                  </div>
                  <div class="orange_bar_gradient width100"></div>
                  <div v-if="show_topup_cc_desktop" class="top10 border0 ion-padding">
                      <!--                      <form action="/home/topup_voucher_front_page" accept-charset="UTF-8" data-remote="true" method="post"><input name="utf8" type="hidden" value="&#x2713;" />-->
                      <div id='topup_mobile' class="flex-column align-items-center1 flex-justify-content-center top30 left30 width100">
                              <div class="subtitluCartelaNextM2m">{{t.topup_nextm2m_account}}</div>
                              <div class="flex-row flex-around align-items-center left10 right10 top20">
                                <div>
                                  <img src="../assets/images/sigla_btpay.png" class="siglaDesktop" alt="logo BT">
                                </div>
                                <div>
                                  <img src="../assets/images/sigla_visa.png" class="siglaDesktop" alt="logo BT">
                                </div>
                                <div>
                                  <img src="../assets/images/sigla_mastercard.png" class="siglaDesktop" alt="logo BT">
                                </div>
                          </div>

                          <div class="flex-row flex-start border0 align-items-center top30 ">
                              <div id="topupLabelTelefon" class="flex1 border0">
                                 {{t.topup_phone_number}}
                              </div>
                              <div id="topupFieldTelefon" class=" border0 ">
                                  <ion-input type="number" label="false" required="required" v-model="enteredMsisdn"/>
                              </div>
                          </div>

                          <div id="wrapRechargeValues" class="flex-row border0 flex-start top20 align-items-center top30">
                              <div class="flex1 border0">
                                  {{t.topup_value_credit}}:
                              </div>
                              <div class="flex1 border0">
                                  <ion-select v-model="selectedRechargeAmount" aria-label="fruit" placeholder="....valori disponibile">
                                    <ion-select-option :selected="true" :value="recharging_values[5].value">{{recharging_values[5].value}} &euro; {{t.validity}} {{recharging_values[5].validity}} {{t.months}} </ion-select-option>
                                    <ion-select-option :value=recharging_values[10].value>{{recharging_values[10].value}} &euro; {{t.validity}} {{recharging_values[10].validity}} {{t.year}} </ion-select-option>
                                    <ion-select-option :value=recharging_values[15].value>{{recharging_values[15].value}} &euro; {{t.validity}} {{recharging_values[15].validity}} {{t.years}} </ion-select-option>
                                    <ion-select-option :value=recharging_values[20].value>{{recharging_values[20].value}} &euro; {{t.validity}} {{recharging_values[20].validity}} {{t.years}} </ion-select-option>
                                    <ion-select-option :value=recharging_values[25].value>{{recharging_values[25].value}} &euro; {{t.validity}} {{recharging_values[25].validity}} {{t.years}} </ion-select-option>
                                    <ion-select-option :value=recharging_values[30].value>{{recharging_values[30].value}} &euro; {{t.validity}} {{recharging_values[30].validity}} {{t.years}} </ion-select-option>
                                  </ion-select>
                                </div>
                          </div>

                        <div class="flex-row flex-start border0 align-items-center top30 ">
                          <div id="topupLabelTelefon" class="flex1 border0">
                            {{t.topup_email}}
                          </div>
                          <div id="topupFieldTelefon" class=" border0 ">
                            <ion-input type="email" label="false" required="required" v-model="enteredEmail"/>
                          </div>
                        </div>
                          <div class="flex-row flex-around align-items-center border0 top30">
                              <div class=" ">
                                  <ion-button  @click="goToHome" color="danger"  class="" >{{t.btn_cancel}} </ion-button>
                              </div>
                              <div class=" ">
                                  <ion-button  color="none" @click.prevent="confirm_recharge_by_cc" value="" class="btnComandaMic" >{{t.btn_buy}}</ion-button>
                              </div>
                          </div>


                      </div>
                      <!--                      </form>-->
                  </div>
                </div>

              <div v-if="show_section_tariffs" class=" max800 padding_20">
                  <div class="top20 ">
                      <div class="width800">
                          <div class="width100 div_text_center">
                              <h4>{{t.you_have_flexible_tariffs_title}}</h4>
                          </div>
                          <div class="flex-row flex-justify-content-space-evenly align-items-center width100 text-center top10 border0">
                              <div  @click="goToContact" class="left20 lnkContact flex3 font18 whiteLink black_bar padding_10 cursorPointer border0 flex-row flex-center align-items-center top10">{{t.band_contact_an_expert}} </div>
                              <div @click="goToHome" class="right20 lnkHome flex3 font18 whiteLink orange_bar left30 padding_10 cursorPointer border0 ion-text-center top10 " >{{t.btn_buy}}</div>
                          </div>

                          <div class="top20 width100 orange_bar_gradient padding_20">
                              <div class="font24">{{t.prepaid_nextm2m_card_title}} </div>
                          </div>
                          <div class="top20"><h4>{{t.details}}:</h4></div>
                          <div>
                              <ul>

                                  <li>
                                      {{t.initial_validity_of_prepaid_card_is}} {{recharging_values[5].validity}} {{t.months}}.
                                  </li>
                                  <li>
                                      {{t.prepaid_validity_is_between}} {{recharging_values[5].validity}} {{t.months}} şi {{recharging_values[30].validity}} {{t.years}} {{t.depending_of_topup_amount}}
                                  </li>
                                  <li>
                                      {{t.traffic_is_charged_with_a_granularity_of}}
                                  </li>
                                  <li>
                                     {{t.grace_period_statement}}
                                  </li>
                                  <li>
                                      {{t.minimum_topup_value_statement}}
                                  </li>

                              </ul>
                          </div>


                          <div class="top20 width100  padding_t_10 padding_lr_10">
                              <h5><strong>{{t.tariffs}}</strong> </h5>
                          </div>
                          <div  class="top10">
                              <h6>{{t.data}}:	0,01 euro/MB</h6>
                              <h6>SMS:	0,06 euro/SMS</h6>
                          </div>
                        <div class="text-left top10">
                         {{t.tariffs_do_not_include_vat}}
                        </div>
                        <div class="font16 top10">
                          {{t.tariffs_valid_in_followirnd_countries}}
                        </div>

                          <div class="top20 width100 padding_t_10 padding_lr_10 subtitluCartelaNextM2m">
                              {{t.recharging_values}}
                          </div>
                          <div class="width80 div_text_center top10">
                              <table class="tableM2M">
                                  <tr>
                                      <th>{{t.credit}}</th>
                                      <th>{{t.validity}}</th>
                                      <th>{{t.traffic}}</th>
                                  </tr>
                                       <tr>
                                         <td>{{recharging_values[5].value}}&euro;</td>
                                         <td>{{recharging_values[5].validity}} {{t.months}}</td>
                                         <td style="text-align: end">{{recharging_values[5].traffic}}</td>
                                       </tr>
                                       <tr>
                                         <td>{{recharging_values[10].value}}&euro;</td>
                                         <td>{{recharging_values[10].validity}} {{t.year}}</td>
                                         <td style="text-align: end">{{recharging_values[10].traffic}}</td>
                                       </tr>
                                       <tr>
                                         <td>{{recharging_values[15].value}}&euro;</td>
                                         <td>{{recharging_values[15].validity}} {{t.years}}</td>
                                         <td style="text-align: end">{{recharging_values[15].traffic}}</td>
                                       </tr>
                                       <tr>
                                         <td>{{recharging_values[20].value}}&euro;</td>
                                         <td>{{recharging_values[20].validity}} {{t.years}}</td>
                                         <td style="text-align: end">{{recharging_values[20].traffic}}</td>
                                       </tr>
                                       <tr>
                                         <td>{{recharging_values[25].value}}&euro;</td>
                                         <td>{{recharging_values[25].validity}} {{t.years}}</td>
                                         <td style="text-align: end">{{recharging_values[25].traffic}}</td>
                                       </tr>
                                       <tr>
                                         <td>{{recharging_values[30].value}}&euro;</td>
                                         <td>{{recharging_values[30].validity}} {{t.years}}</td>
                                         <td style="text-align: end">{{recharging_values[30].traffic}}</td>
                                       </tr>
                              </table>
                          </div>

                          <div class="lnkContact flex3 font18 whiteLink black_bar padding_10 text-center top10 cursorPointer" @click="goToContact">
                            {{t.request_offer_from_our_specialists}}</div>



                          <div class="top20 width100  padding_t_5 ">
                              <div class="top20 width100  orange_bar_gradient padding_10">
                                  <h4>{{t.subscriptions}} Next-M2M </h4>
                              </div>
                            <div class="top20 width100  padding_t_10 padding_lr_10">
                              <h5><strong>{{t.tariffs}}</strong> </h5>
                            </div>

                            <div class="width80 div_text_center">
                              <table class="tableM2M">
                                <thead >
                                <tr>
                                  <th>
                                    Cost SIM
                                  </th>
                                  <th>
                                    {{t.monthly_subscription}}/SIM
                                  </th>
                                  <th>
                                    {{t.tariff_data}}/MB
                                  </th>
                                  <th>
                                    {{t.tariff_sms}}
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td>{{tarif_sms_postpaid}}&euro;</td>
                                  <td>{{abonament_postpaid}}&euro;  100MB {{t.included_traffic}}.</td>
                                  <td>0.008&euro;</td>
                                  <td>0.06&euro;</td>
                                </tr>
                                </tbody>
                              </table>

                            </div>
                            <div class="text-left top10">
                              {{t.tariffs_do_not_include_vat}}
                            </div>
                            <div class="font16 top10">
                              {{t.tariffs_valid_in_followirnd_countries}}
                              <ul>
                                  <li>
                                    {{t.minimum_validity_contract}}
                                  </li>
                                  <li>
                                      {{t.traffic_is_charged_with_a_granularity_of}}
                                  </li>

                              </ul>
                          </div>

                              <div class="lnkContact flex3 font18 whiteLink black_bar padding_10 text-center top10 cursorPointer"  @click="goToContact"> {{t.request_offer_from_our_specialists}}</div>

                          </div>
                          <div class="top10"> </div>
                      </div>

                  </div>
              </div>

              <div v-if="show_section_aboutNextM2M" class=" max800 padding_20">
                    <div class="top20 ">
                        <div class="width800 ">
                            <div class="width100 div_text_center">
                                <h3>{{t.about_nextm2m_title}}</h3>
                            </div>
                            <div class="top10">{{t.what_is_next_m2m}}
                            </div>
                            <div class="top20 width100  padding_t_10"><h4>{{t.nextm2m_works_all_eu}} </h4></div>
                            <div class="top10">
                              {{t.nextm2m_31_countries}}
                                Next-M2M  oferă acoperire in 31 de țări din Europa și poate folosi rețelele 2G, 3G, 4G.
                            </div>

                            <div class="top20 width100  padding_t_10"><h4 class="">{{t.nextm2m_can_be_used_in_any_device_title}}</h4></div>
                            <div class="top10">
                              {{t.nextm2m_can_be_used_in_any_device_text}}
                            </div>

                            <div class="top20 width100  padding_t_10"><h4>{{t.same_tariff_on_all_ue_countries_title}}</h4></div>
                            <div class="top10">
                                {{t.same_tariff_on_all_ue_countries_text}}
                            </div>

                            <div class="top20 width100  padding_t_10"><h4>{{t.multi_operator_connectivity_title}}</h4></div>
                            <div class="top10">
                              {{t.multi_operator_connectivity_text}}
                            </div>

                            <div class="top20 width100  padding_t_10"><h4>{{t.access_to_a_smarter_world_is_here_title}}</h4></div>
                            <div class="top10">
                              {{t.access_to_a_smarter_world_is_here_text}}
                            </div>

                        </div>


                    </div>
                </div>

              <div v-if="showSectionCarduriPrepaidOrPostpaid" class="border0 top30">
                <div v-if="!is_mobile"  name="divShowPrepaidAndPostpaidCardsDesktop" class="border0 flex-row flex-wrap flex-around align-items-start  width100">
                  <div id="divSimPrepaid" @click="activatePrepaid" class="border0" :class="prepaidSelected?'simPrepaidActive':'simPrepaidInactive'">
                    <div id="divTextSimPrepaid" :class="prepaidSelected?'textSimPrepaidActive':'textSimPrepaidInactive'">
                      <div id="simTypeTitleActive" :class="prepaidSelected?'simTypeTitleActive':'simTypeTitleInActive'">
                        <h3>Prepaid</h3>
                      </div>
                      <div class="font16 padding_lr_10">
                       {{t.prepaid_card_subtitle_description}}
                      </div>
                    </div>
                  </div>
                  <div id="divSimPostpaid"  @click="activatePostpaid" class="border0"  :class="prepaidSelected?'simPostpaidInactive':'simPostpaidActive'">
                    <div id="divTextSimPostpaid" :class="prepaidSelected?'textSimPostpaidInactive':'textSimPostpaidActive'">
                      <div id="simTypeTitleInactive" :class="prepaidSelected?'simTypeTitleInActive':'simTypeTitleActive'">
                        <h3>Postpaid</h3>
                      </div>
                      <div  class="font16">
                        {{t.postpaid_card_subtitle_description}}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="is_mobile" name="divShowPrepaidAndPostpaidCardsMobile" class="border0 flex-row  flex-around align-items-start  width100">
                  <div id="divSimPrepaidMobile" @click="activatePrepaid" class="border0" :class="prepaidSelected?'simPrepaidActiveMobile':'simPrepaidInactiveMobile'">
                    <div id="simTypeTitleActive" class="border0" :class="prepaidSelected?'simTypeTitleActiveMobile  left20 top40':'simTypeTitleInActiveMobile  left10 top30'">
                      Prepaid
                    </div>
                    <div id="divTextSimPrepaid" class="border0 font10  left10 padding_lr_10" :class="prepaidSelected?' top30':' top30'">
                     {{t.prepaid_card_subtitle_description}}
                    </div>
                  </div>

                  <div id="divSimPostpaid"  @click="activatePostpaid" class="border0"  :class="prepaidSelected?'simPostpaidInactiveMobile':'simPostpaidActiveMobile'">
                    <div id="simTypeTitleInactive" :class="prepaidSelected?'simTypeTitleInActiveMobile left10 top30':'simTypeTitleActiveMobile left20 top40'">
                      Postpaid
                    </div>
                    <div id="divTextSimPostpaid" class="border0 font10  left10" :class="prepaidSelected?' top30':' top30'">
                      Cele mai accesibile SIM-uri de date cu <br> abonament lunar.
                    </div>
                  </div>
                </div>
                  <!--                sectiunea prepaid-->
                <div v-if="!is_mobile && prepaidSelected && showSectionPachetePrepaidDisponibile" id="sectionPachetePrepaidDisponibileDesktop" class="sectionPachetePrepaidDisponibile border0">
                    <div class="wrapper_comanda border0" name="wrapper comanda prepaid">
                      <div id="divOrangeBarPrepaidOrder" class="flex-row flex-justify-content-space-evenly align-items-center orange_bar border0 orange_bar  barHeightPrepaidSubtitle width100 colorBlack top30  border0 ">
                        <div class="font22 top5">{{t.band_order_prepaid_sim_cards}}</div>
                      </div>
                      <div id="divOrangeBarPacheteDisponibile" class=" flex-row flex-justify-content-space-between align-items-center barHeight width100 colorBlack top18 padding_lr_10 width100">
                        <div id="divOrangeBarPacheteDisponibilePO" class=" flex-row flex-justify-content-space-between align-items-center barHeight width100 colorBlack top20 padding_lr_10 width100   ">
                          <div id="divOrangeBarPachetePO" class="orange_bar_gradient_prepaid_packages barHeightPrepaidPackages width100  top10 padding_lr_10 ">
                            <div id="bar_prepaid_package_discounts" style="position:relative" class="flex-row align-items-center flex-around top10">
                              <div @click.prevent="set_nr_of_prepaid_sim(10)" id="pack20" class="left30 ">
                                <div class="ion-text-center">
                                  <div style="font-size: 24px;color: white; font-weight: bolder">
                                    -3%
                                  </div>
                                  <div style="font-size: 24px;color: black;">
                                    10 SIM
                                  </div>
                                </div>
                              </div>
                             <div @click.prevent="set_nr_of_prepaid_sim(20)" id="pack20" class="left30 ">
                                <div class="ion-text-center">
                                  <div style="font-size: 24px;color: white; font-weight: bolder">
                                    -5%
                                  </div>
                                  <div style="font-size: 24px;color: black;">
                                    20 SIM
                                  </div>
                                </div>
                              </div>
                              <div @click.prevent="set_nr_of_prepaid_sim(50)" id="pack20" class="left30 ">
                                <div class="ion-text-center">
                                  <div style="font-size: 24px;color: white; font-weight: bolder">
                                    -7%
                                  </div>
                                  <div style="font-size: 24px;color: black;">
                                    50 SIM
                                  </div>
                                </div>
                              </div>
                              <div @click.prevent="set_nr_of_prepaid_sim(100)" id="pack20" class="left30 ">
                                <div class="ion-text-center">
                                  <div style="font-size: 24px;color: white; font-weight: bolder">
                                    -10%
                                  </div>
                                  <div style="font-size: 24px;color: black;">
                                    100 SIM
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="wrapper_comanda_prepaid" class=" flex-row top30 border0" >
                      <div class="divDetaliiPachet10 flex2 font16 top20 left30 border0" >
                        <div style="" class="subtitluCartelaNextM2m top10">{{t.nextm2m_prepaid_sim_card}}</div>
                        <div class="top10">{{t.initial_credit}} {{cost_prepaid_sim}}&euro;. </div>
                        <div class="top10">{{t.validity}} {{validity_prepaid_sim}}. </div>
<!--                        <div class="top10">500MB trafic inclus. </div>-->
                        <div class="top10">{{t.unique_tariff_romania_and_ue}} - {{tarif_date_prepaid}}€/GB .</div>
<!--                          <div>Taxarea traficului se face la 10kB.</div>-->
                        <div v-if="showRechargingOptionPrepaid">
                          <div id="wrapRechargeValues" class="top10">
                            <div class="subtitluCartelaNextM2m top10">
                             {{t.recharging_values}}:
                            </div>
                            <div class="left10 top10">
                              <table class="m2mValability">
                                <tr>
                                  <th>{{t.value}}</th>
                                  <th>{{t.validity}}</th>
                                  <th>{{t.traffic}}</th>
                                </tr>
                                  <tr>
                                    <td>{{recharging_values[5].value}} &euro; </td>
                                    <td>{{recharging_values[5].validity}} {{t.months}} </td>
                                    <td style="text-align: end">{{recharging_values[5].traffic}} </td>
                                  </tr>
                                  <tr>
                                    <td>{{recharging_values[10].value}} &euro;</td>
                                    <td> {{recharging_values[10].validity}} {{t.year}}</td>
                                    <td style="text-align: end"> {{recharging_values[10].traffic}}</td>
                                  </tr>
                                  <tr>
                                    <td>{{recharging_values[15].value}} &euro;</td>
                                    <td> {{recharging_values[15].validity}} {{t.years}}</td>
                                    <td style="text-align: end"> {{recharging_values[15].traffic}}</td>
                                  </tr>
                                  <tr>
                                    <td>{{recharging_values[20].value}} &euro;</td>
                                    <td> {{recharging_values[20].validity}} {{t.years}}</td>
                                    <td style="text-align: end"> {{recharging_values[20].traffic}}</td>
                                  </tr>
                                  <tr>
                                    <td>{{recharging_values[25].value}} &euro;</td>
                                    <td> {{recharging_values[25].validity}} {{t.years}}</td>
                                    <td style="text-align: end"> {{recharging_values[25].traffic}}</td>
                                  </tr>
                                  <tr>
                                    <td>{{recharging_values[30].value}} &euro;</td>
                                    <td> {{recharging_values[30].validity}} {{t.years}}</td>
                                    <td style="text-align: end"> {{recharging_values[30].traffic}}</td>
                                  </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="divListaButoaneDetaliiSim" class="listaButoaneDetaliiSim flex-column flex3 left30 ">
                          <div class="flex-row flex-justify-content-space-between shadowExt5px top20  padding_10 ">
                            <div class="" >{{t.price}}</div>
                            <div>
                              <div id="cost_per_sim"> {{cost_prepaid_sim}}€</div>
                            </div>
                          </div>

                          <div class="flex-row align-items-center flex-justify-content-space-between shadowExt5px top20 padding_lr_10 align-items-center">
                            <div title="discount la comenzi mari">{{ t.nr_of_sim_cards}}</div>
                            <div class="flex-row align-items-center flex-end">
                                <div @click="decreaseNrOfPrepaidSim" class="border0">
                                    <ion-icon :icon="removeCircleOutline" class="border0" style="font-size: 36px;color:grey"></ion-icon>
                                </div>
                                <div id="div_nr_of_sims_PP" title="minim 100 SIM-uri" class="flex-row flex-center border0">
                                    <input id="nr_of_sims_PP"   @keyup="checkPrepaidMinMaxNrOfSims" v-model="nr_of_prepaid_sims" class="ion-text-center" style="border:none" type="tel" size="3" />
                                </div>
                                <div @click="increaseNrOfPrepaidSim" class="border0 left10">
                                    <ion-icon :icon="addCircleOutline" style="font-size: 36px;color:darkgrey"></ion-icon>
                                </div>
                            </div>
                          </div>

                          <div class="flex-row flex-justify-content-space-between shadowExt5px top20  padding_10">
                            <div class="top2" > Discount </div>
                            <div id="discountPackage" title="discountPackage" class="top2" > {{discountPrepaid}}%</div>
                          </div>


                          <div class="flex-row flex-justify-content-space-between shadowExt5px top20  padding_lr_10">
                            <div class="top2" > {{t.total_order}} </div>
                            <div class="flex-row flex-justify-content-end align-items-center">
                              <div id="totalCostPackageAndTraffic" title="totalCostPackageAndTraffic" class="left2" >{{totalCommandAmountPrepaid}}</div>
                              <div class="left2"> &euro;</div>
                            </div>
                          </div>

                          <div class="flex-row flex-justify-content-space-between shadowExt5px top20  padding_10">
                              <div class="top2" > {{t.delivery_cost}} </div>
                              <div id="discountPackage" title="transport" class="top2" > {{transport_cost}} lei</div>
                          </div>

                          <div class="flex-row flex-justify-content-space-between shadowExt5px top20  padding_lr_10">
                            <div class="top2" >{{t.total_payment_including_vat}}</div>
                            <div class="flex-row flex-justify-content-end align-items-center">
                              <div id="totalCostPackageAndTraffic" title="totalCostPackageAndTraffic" class="left2" >{{((parseFloat(totalCommandAmountPrepaid)*euro+transport_cost)*1.19).toFixed(2)}}</div>
                              <div class="left2">lei</div>
                            </div>
                          </div>
                         <div class="flex-row flex-justify-content-end top30 ">
                            <div v-if="prepaidSelected" @click="confirm_prepaid_command"><button id="btnTrimiteComandaPP" class="btnComanda"  type="button">{{t.btn_order}}</button></div>
                            <div  v-if="!prepaidSelected" @click="confirm_postpaid_command"><button id="btnTrimiteComandaPO" class="btnComanda"  type="button">{{t.btn_order}}</button></div>
                        </div>
                      </div>
                      </div>
                    </div>
                </div>
                <div v-if="is_mobile && prepaidSelected && showSectionPachetePrepaidDisponibile"  id="sectionPachetePrepaidDisponibileMobil" class="sectionPachetePrepaidDisponibile ">
                    <div class="wrapper_comanda" name="wrapper comanda prepaid">
                      <div id="divOrangeBarPrepaidOrder" class="flex-row flex-justify-content-space-evenly align-items-center orange_bar maxWidth800 border0 orange_bar  barHeightPrepaidSubtitle width100 colorBlack top30 padding_20 border0 ">
                        <div class="top5 font23 ">{{t.band_order_prepaid_sim_cards}}</div>
                      </div>
                      <div id="divOrangeBarPacheteDisponibile" class=" flex-row flex-justify-content-space-between align-items-center barHeight width100 colorBlack top20 padding_lr_10 width100">
                        <div id="divOrangeBarPachet10" class="orange_bar_gradient_prepaid_packages barHeightPrepaidPackages width100  top10 padding_lr_10 ">
                          <div id="bar_prepaid_package_discounts" style="position:relative" class="flex-row align-items-center flex-justify-content-space-between top10">
                            <div @click.prevent="set_nr_of_prepaid_sim(10)" id="pack20">
                              <div class="ion-text-center">
                                <div style="font-size: 18px;color: white;font-weight: bolder">
                                  -3%
                                </div>
                                <div style="font-size: 18px;color: black;">
                                  10 SIM
                                </div>
                              </div>
                            </div>
                            <div @click.prevent="set_nr_of_prepaid_sim(20)" id="pack20">
                              <div class="ion-text-center">
                                <div style="font-size: 18px;color: white;font-weight: bolder">
                                  -5%
                                </div>
                                <div style="font-size: 18px;color: black;">
                                  20 SIM
                                </div>
                              </div>
                            </div>
                            <div @click.prevent="set_nr_of_prepaid_sim(50)" id="pack20">
                              <div class="ion-text-center">
                                <div style="font-size: 18px;color: white;font-weight: bolder">
                                  -7%
                                </div>
                                <div style="font-size: 18px;color: black;">
                                  50 SIM
                                </div>
                              </div>
                            </div>
                            <div @click.prevent="set_nr_of_prepaid_sim(100)" id="pack20">
                              <div class="ion-text-center">
                                <div style="font-size: 18px;color: white;font-weight: bolder">
                                  -10%
                                </div>
                                <div style="font-size: 18px;color: black;">
                                  100 SIM
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="wrapper_comanda_prepaid" class="top30 " >
                        <div class="divDetaliiPachet10  font16 left10 top40 " >

                          <div class="top10">{{t.initial_credit}} {{cost_prepaid_sim}}&euro;. </div>
                          <div class="top10">{{t.validity}} {{validity_prepaid_sim}}. </div>
<!--                          <div class="top10">500MB trafic inclus. </div>-->
                          <div class="top10">{{t.unique_tariff_romania_and_ue}} - {{tarif_date_prepaid}}€/GB.</div>

                        </div>
                        <div id="divListaButoaneDetaliiSim" class="listaButoaneDetaliiSim flex-column flex3 left30 ">

                          <div class="flex-row flex-justify-content-space-between shadowExt5px top20  padding_10 ">
                            <div >Preț</div>
                            <div>
                              <div id="cost_per_sim"> {{cost_prepaid_sim}} €</div>
                            </div>
                          </div>

                            <div class="flex-row align-items-center flex-justify-content-space-between border0 shadowExt5px top20 padding_lr_10 align-items-center">
                                <div title="">Număr cartele SIM  </div>
                                <div class="flex-row align-items-center flex-end">
                                    <div @click="decreaseNrOfPrepaidSim" class="border0">
                                        <ion-icon :icon="removeCircleOutline" class="border0" style="font-size: 36px;color:grey"></ion-icon>
                                    </div>
                                  <div id="div_nr_of_sims_PP"  @keyup="checkPrepaidMinMaxNrOfSims"  title="" class="ion-text-center border0">
                                        <input id="nr_of_sims_PP" v-model="nr_of_prepaid_sims" class="ion-text-center" style="border:none" type="tel" size="3" />
                                    </div>
                                    <div @click="increaseNrOfPrepaidSim" class="border0 left0">
                                        <ion-icon :icon="addCircleOutline" style="font-size: 36px;color:darkgrey"></ion-icon>
                                    </div>
                                </div>
                            </div>

                          <div class="flex-row flex-justify-content-space-between shadowExt5px top20  padding_10" >
                            <div class="top2" > Discount </div>
                            <div id="discountPackage" title="discountPackage" class="top2" > {{discountPrepaid}}%</div>
                          </div>

                          <div class="flex-row flex-justify-content-space-between shadowExt5px top20  padding_lr_10">
                            <div class="top2" >{{t.total_order}}</div>
                            <div class="flex-row flex-justify-content-end align-items-center">
                              <div id="totalCostPackageAndTraffic" title="totalCostPackageAndTraffic" class="left2" >{{totalCommandAmountPrepaid}}</div>
                              <div class="left2"> &euro;</div>
                            </div>
                          </div>

                            <div class="flex-row flex-justify-content-space-between shadowExt5px top20  padding_10">
                                <div class="top2" > {{t.delivery_cost}} </div>
                                <div id="discountPackage" title="transport" class="top2" > {{transport_cost}} lei</div>
                            </div>

                            <div class="flex-row flex-justify-content-space-between shadowExt5px top20  padding_lr_10">
                                <div class="top2" > {{t.total_payment_including_vat}}</div>
                                <div class="flex-row flex-justify-content-end align-items-center">
                                    <div id="totalCostPackageAndTraffic" title="totalCostPackageAndTraffic" class="left2" >{{((parseFloat(totalCommandAmountPrepaid)*euro+transport_cost)*1.19).toFixed(2)}}</div>
                                    <div class="left2">lei</div>
                                </div>
                            </div>

                        </div>
                          <div class="flex-row flex-center top30 border0 width100">
                              <button  @click="confirm_prepaid_command" id="btnTrimiteComandaPP" class="btnComanda">{{t.btn_order}}</button>
                          </div>
                          <div class="heigth30"></div>
                      </div>
                    </div>
                  </div>
                  <!--                sectiunea postpaid-->
                <div v-if="!is_mobile && !prepaidSelected && showSectionPachetePostpaidDisponibile" id="sectionPachetePrepaidDisponibileDesktop" class="sectionPachetePrepaidDisponibile border0 wrapper_comanda">
                      <div id="divOrangeBarPostpaidOrder" class="flex-row flex-justify-content-space-evenly border0 align-items-center orange_bar  orange_bar font27 barHeightPrepaidSubtitle width100 colorBlack top30 padding_20 ">
                          <div class="top5 font23">{{t.band_order_postpaid_sim_cards}}</div>
                      </div>
                      <div id="divOrangeBarPacheteDisponibilePO" class=" flex-row flex-justify-content-space-between align-items-center barHeight width100 colorBlack top20 padding_lr_10 width100   ">
                          <div id="divOrangeBarPachetePO" class="orange_bar_gradient_prepaid_packages barHeightPrepaidPackages width100  top10 padding_lr_10 ">
                            <div id="bar_prepaid_package_discounts" style="position:relative" class="flex-row align-items-center flex-around top10">
                            <div @click.prevent="set_nr_of_postpaid_sim(100)" id="pack20" class="left30 ">
                              <div class="ion-text-center">
                                <div style="font-size: 24px;color: white; font-weight: bolder">
                                  -5%
                                </div>
                                <div style="font-size: 24px;color: black;">
                                  100 SIM
                                </div>
                              </div>
                            </div>
                            <div @click.prevent="set_nr_of_postpaid_sim(500)" id="pack20">
                              <div class="ion-text-center">
                                <div style="font-size: 24px;color: white; font-weight: bolder">
                                  -10%
                                </div>
                                <div style="font-size: 24px;color: black;">
                                  500 SIM
                                </div>
                              </div>
                            </div>
                            <div @click.prevent="set_nr_of_postpaid_sim(1000)" id="pack50">
                              <div class="ion-text-center">
                                <div style="font-size: 24px;color: white; font-weight: bolder">
                                  -15%
                                </div>
                                <div style="font-size: 24px;color: black;">
                                  1000 SIM
                                </div>
                              </div>
                            </div>
                            <div @click.prevent="set_nr_of_postpaid_sim(2000)" id="pack100" class="right30">
                              <div class="ion-text-center">
                                <div style="font-size: 24px;color: white; font-weight: bolder">
                                  -20%
                                </div>
                                <div style="font-size: 24px;color: black;">
                                  2000 SIM
                                </div>
                              </div>
                            </div>
                          </div>
                          </div>
                      </div>
                      <div class="flex-row top30">
                          <div class="divDetaliiPachet10 flex2 font16 top30 " >
                              <!--      Trafic de date inclus 200 MB/SIM<br>-->

                            <div style="" class="subtitluCartelaNextM2m ">{{t.nextm2m_postpaid_sim_card}}</div>
                            <div class="top10">{{t.monthly_subscription}} {{cost_postpaid_abonament_sim}} &euro;/{{t.month}};</div>
                            <div class="top10">{{t.included_traffic}} 100 MB/{{t.month}}.</div>
                            <div class="top10">{{t.unique_tariff_romania_and_ue}} - {{tarif_date_postpaid}}€/GB.</div>
                            <div class="top10"> {{t.minimum_validity_contract}}</div>
                            <!--      Preț SIM 50 lei-->
                          </div>
                          <div id="divDetaliiComandaDesktop" class="listaButoaneDetaliiSim flex-column flex3  ">
                            <div class="flex-row flex-justify-content-space-between shadowExt5px top20  padding_10">
                              <div class="" >{{t.cost_postpaid_sim_cards}}</div>
                              <div class="flex-row">
                                <div id="sim_price_postpaid"> </div> <div class="left2">{{cost_postpaid_sim}}€</div>
                              </div>
                            </div>
                              <div class="flex-row align-items-center flex-justify-content-space-between shadowExt5px top20 padding_lr_10 align-items-center">
                                  <div title="discount la comenzi mari">{{ t.nr_of_sim_cards}}</div>
                                  <div class="flex-row align-items-center flex-end">
                                      <div @click="decreaseNrOfPostpaidSim" class="border">
                                          <ion-icon :icon="removeCircleOutline" class="border0" style="font-size: 36px;color:grey"></ion-icon>
                                      </div>
                                      <div id="div_nr_of_sims_PP"  @keyup="checkPostpaidMinMaxNrOfSims" title="minim 10 SIM-uri" style="position: relative;left:13px;" class="flex-row flex-center border0">
                                          <input id="nr_of_sims_PP" v-model="nr_of_postpaid_sims" style="border:none" type="tel" size="3" />
                                      </div>
                                      <div @click="increaseNrOfPostpaidSim" class="border0 left10">
                                          <ion-icon :icon="addCircleOutline" style="font-size: 36px;color:darkgrey"></ion-icon>
                                      </div>
                                  </div>
                              </div>



<!--                              <div class="flex-row flex-justify-content-space-between shadowExt5px top20 padding_lr_10 ">-->
<!--                                  <div>Tarif abonament lunar</div>-->
<!--                                  <div class="flex-row">-->
<!--                                      <div id="cost_abonament_postpaid_per_sim_PO">-->
<!--                                          {{cost_postpaid_abonament_sim}}-->
<!--                                      </div>-->
<!--                                      <div  class="left1">-->
<!--                                          &euro;/lună;-->
<!--                                      </div>-->
<!--                                  </div>-->
<!--                              </div>-->

                            <div class="flex-row flex-justify-content-space-between shadowExt5px top20  padding_10">
                              <div class="top2" >{{t.subscription_discount}}</div>
                              <div id="discountPackage" title="transport" class="top2" > {{discountPostpaid}}%</div>
                            </div>

                             <div class="flex-row flex-justify-content-space-between shadowExt5px top20  padding_10">
                              <div class="top2" > {{t.total_monthly_subscription}}</div>
                               <div v-if="debug">
                                 nr_of_postpaid_sims={{nr_of_postpaid_sims}}
                                 totalCostSubscriptionPostpaid={{totalCostSubscriptionPostpaid}}
                                 discountPostpaid={{discountPostpaid}}
                               </div>
                              <div id="discountPackage" title="transport" class="top2" > {{totalCostSubscriptionPostpaid}} &euro;</div>
                            </div>

                            <div class="flex-row flex-justify-content-space-between shadowExt5px top20  padding_lr_10">
                                  <div class="top2" >{{t.total_cost_simcards}}</div>
                                  <div class="flex-row flex-justify-content-end align-items-center">
                                      <div id="totalCostPackageAndTraffic" title="totalCostPackageAndTraffic" class="left2" >{{totalCommandAmountPostpaid}}</div>
                                      <div class="left2"> &euro;</div>
                                  </div>
                              </div>


                             <div class="flex-row flex-justify-content-space-between shadowExt5px top20  padding_10">
                                  <div class="top2" > {{t.delivery_cost}} </div>
                                  <div id="discountPackage" title="transport" class="top2" > {{transport_cost}} lei</div>
                              </div>

                              <div class="flex-row flex-justify-content-space-between shadowExt5px top20  padding_lr_10">
                                  <div class="top2" > {{t.total_payment_including_vat}}</div>
                                  <div class="flex-row flex-justify-content-end align-items-center">
                                      <div id="totalCostPackageAndTraffic" title="totalCostPackageAndTraffic" class="left2" >{{((parseFloat(totalCommandAmountPostpaid)*euro+transport_cost)*1.19).toFixed(2)}}</div>
                                      <div class="left2">lei</div>
                                  </div>
                              </div>
                              <div class="flex-row flex-justify-content-end top30 ">
                                  <div @click="confirm_postpaid_command"><button id="btnFinalizareComandaPODesktop" class="btnComanda"  type="button">{{t.btn_order}}</button></div>
                              </div>
                          </div>
                      </div>
                  </div>
                <div v-if="is_mobile && !prepaidSelected && showSectionPachetePostpaidDisponibile" id="sectionPachetePostpaidDisponibileMobil" class="sectionPachetePrepaidDisponibile ">
                    <div class="wrapper_comanda" name="wrapper comanda prepaid">
                      <div id="divOrangeBarPrepaidOrder" class="flex-row flex-justify-content-space-evenly align-items-center orange_bar maxWidth800 border0 orange_bar  barHeightPrepaidSubtitle width100 colorBlack top30 padding_lr_10 border0 ">
                        <div class="top5 font23 ">{{t.band_order_postpaid_sim_cards}}</div>
                      </div>
                      <div id="divOrangeBarPacheteDisponibile" class=" flex-row flex-justify-content-space-between align-items-center barHeight width100 colorBlack top20 padding_lr_10 width100">
                        <div id="divOrangeBarPachet10" class="orange_bar_gradient_prepaid_packages barHeightPrepaidPackages width100  top10 padding_lr_10 ">
                          <div id="bar_prepaid_package_discounts" style="position:relative" class="flex-row align-items-center flex-justify-content-space-between top10">
                            <div @click.prevent="set_nr_of_postpaid_sim(100)" id="pack20">
                              <div class="ion-text-center">
                                <div style="font-size: 18px;color: white;font-weight: bolder">
                                    -5%
                                  </div>
                                  <div style="font-size: 18px;color: black;">
                                    100 SIM
                                  </div>
                              </div>
                            </div>
                            <div @click.prevent="set_nr_of_postpaid_sim(500)" id="pack20">
                              <div class="ion-text-center">
                                <div style="font-size: 18px;color: white;font-weight: bolder">
                                  -10%
                                </div>
                                <div style="font-size: 18px;color: black;">
                                  500 SIM
                                </div>
                              </div>
                            </div>
                            <div @click.prevent="set_nr_of_postpaid_sim(1000)" id="pack50">
                              <div class="ion-text-center">
                                <div style="font-size: 18px;color: white;font-weight: bolder">
                                  -15%
                                </div>
                                <div style="font-size: 18px;color: black;">
                                  1000 SIM
                                </div>
                              </div>
                            </div>
                            <div @click.prevent="set_nr_of_postpaid_sim(2000)" id="pack100" class="right10">
                              <div class="ion-text-center">
                                <div style="font-size: 18px;color: white;font-weight: bolder">
                                  -20%
                                </div>
                                <div style="font-size: 18px;color: black;">
                                  2000 SIM
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="top30">
                            <div class="divDetaliiPachet10 flex2 font16 top40 " >
                                <!--      Trafic de date inclus 200 MB/SIM<br>-->
                              <div class="top10">{{t.unique_tariff_romania_and_ue}} {{tarif_date_postpaid}}&euro;/GB.</div>
                              <div class="top10">{{t.traffic_quota}} 10kB.</div>
                              <div class="top10">{{t.minimum_validity_contract}}</div>
                                <br>
                                <!--      Preț SIM 50 lei-->
                            </div>
                            <div id="divListaButoaneDetaliiSimPO" class="listaButoaneDetaliiSim flex-column flex3  ">

                              <div class="flex-row flex-justify-content-space-between shadowExt5px top20  padding_10">
                                <div class="" >{{t.cost_postpaid_sim_cards}}</div>
                                <div class="flex-row">
                                  <div id="sim_price_postpaid"> </div> <div class="left2">{{cost_postpaid_sim}}€</div>
                                </div>
                              </div>

                                <div class="flex-row align-items-center flex-justify-content-space-between shadowExt5px top20 padding_lr_10 align-items-center">
                                    <div title="discount la comenzi mari">{{ t.nr_of_sim_cards}}</div>
                                    <div class="flex-row align-items-center flex-end">
                                        <div @click="decreaseNrOfPostpaidSim" class="border0">
                                            <ion-icon :icon="removeCircleOutline" class="border0" style="font-size: 36px;color:grey"></ion-icon>
                                        </div>
                                        <div id="div_nr_of_sims_PP" title="minim 10 SIM-uri" class="flex-row flex-center border0" style="position: relative;left:13px;">
                                            <input id="nr_of_sims_PP" @keyup="checkPostpaidMinMaxNrOfSims" v-model="nr_of_postpaid_sims"  style="border:none" type="tel" size="3" />
                                        </div>
                                        <div @click="increaseNrOfPostpaidSim" class="border0 left10">
                                            <ion-icon :icon="addCircleOutline" style="font-size: 36px;color:darkgrey"></ion-icon>
                                        </div>
                                    </div>
                                </div>

                              <div class="flex-row flex-justify-content-space-between shadowExt5px top20  padding_10">
                                <div class="top2" > {{t.total_monthly_subscription}}</div>
                                <div v-if="debug">
                                  nr_of_postpaid_sims={{nr_of_postpaid_sims}}
                                  totalCostSubscriptionPostpaid={{totalCostSubscriptionPostpaid}}
                                  discountPostpaid={{discountPostpaid}}
                                </div>
                                <div id="discountPackage" title="transport" class="top2" > {{totalCostSubscriptionPostpaid}} &euro;</div>
                              </div>


                              <div class="flex-row flex-justify-content-space-between shadowExt5px top20  padding_10">
                                <div class="top2" >a {{t.subscription_discount}}</div>
                                <div id="discountPackage" title="transport" class="top2" > {{discountPostpaid}}%</div>
                              </div>


                              <div class="flex-row flex-justify-content-space-between shadowExt5px top20  padding_lr_10">
                                <div class="top2" >{{t.total_cost_simcards}}</div>
                                <div class="flex-row flex-justify-content-end align-items-center">
                                  <div id="totalCostPackageAndTraffic" title="totalCostPackageAndTraffic" class="left2" >{{totalCommandAmountPostpaid}}</div>
                                  <div class="left2"> &euro;</div>
                                </div>
                              </div>

                                <div class="flex-row flex-justify-content-space-between shadowExt5px top20  padding_10">
                                    <div class="top2" > {{t.delivery_cost}} </div>
                                    <div id="discountPackage" title="transport" class="top2" > {{transport_cost}} lei</div>
                                </div>

                                <div class="flex-row flex-justify-content-space-between shadowExt5px top20  padding_lr_10">
                                    <div class="top2" > {{t.total_payment_including_vat}}</div>
                                    <div class="flex-row flex-justify-content-end align-items-center">
                                        <div id="totalCostPackageAndTraffic" title="totalCostPackageAndTraffic" class="left2" >{{((parseFloat(totalCommandAmountPostpaid)*euro+transport_cost)*1.19).toFixed(2)}}</div>
                                        <div class="left2">lei</div>
                                    </div>
                                </div>
                                <div class="flex-row flex-justify-content-end top30 ">
                                    <div @click="confirm_postpaid_command"><button id="btnTrimiteComandaPostpaidMobil" class="btnComanda"  type="button">{{t.btn_order}}</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
              <div v-if="show_section_contact" class="left30">
                    <div class="top10">
                        <div class="desktop-only sectionTitleDesktop ">
                            Contact Next-M2M
                        </div>
                    </div>
                    <div id='divWrapperContactPage' class="ion-padding ">
                        <div class="flex-column ">
                            <div class="top10">Vânzări: vanzari@nextm2m.ro</div>
                            <div class="top10">Tehnic:  suport@nextm2m.ro</div>
                            <div class="top10">Urgente Tehnic:  +40 764 111 211</div>
                            <div class="top10">Email: office©nextm2m.ro </div>
                        </div>
                        <div  class="left20 ">
                            <div class="flex-row flex-justify-content-start align-items-center top10 width250">
                                <ion-input  class="ion-text-start" type="name" v-model="contact_name" placeholder="...nume" name="contact[name]" id="contact_name" />
                            </div>
                        </div>

                        <div class="flex-row top10 flex-justify-content-start align-items-center">
                            <div class="left20 width250">
                                <ion-input type="email" v-model="contact_email" class="contact-form-text-area  ion-text-start "  placeholder="...email" name="contact[email]" id="contact_email" />
                            </div>
                        </div>

                        <div class="flex-row top10 flex-justify-content-start align-items-center">

                            <div class="left20 width250">
                                <ion-input v-model="contact_phone" type="tel" class="contact-form-text-area ion-text-start " placeholder="...telefon" name="contact[phone]" id="contact_phone" />
                            </div>
                        </div>

                        <div class="flex-row top10 flex-justify-content-start align-items-center">

                            <div class="left20">
                                <ion-textarea
                                        v-model="contact_text"
                                        label="Custom Counter Format"
                                        label-placement="floating"
                                        :counter="true"
                                        :auto-grow="true"
                                        maxlength="500"
                                        :counter-formatter="customFormatter"
                                        placeholder="Mesaj ...."
                                        id="contact_message">
                                </ion-textarea>
                            </div>
                        </div>

                        <div id="contactFormSubmit" class="flex-row top20 flex-justify-content-center align-items-center  top30 width400" :class="is_mobile?'relativeLeft30':'relativeLeft30'">
                            <div>
                                <ion-button @click="goToHome" :class="is_mobile?'btnWidth100':'btnWidth100'" id='btnCancelContactFormSubmit' color="danger">{{t.btn_cancel}}</ion-button>
                            </div>
                            <div class="left20">
                                <ion-button @click="recaptchaContact"  :class="is_mobile?'btnWidth100':'btnWidth100'"  class="left20" data-disable-with="Send Message" >{{t.btn_send}}</ion-button>
<!--                                <ion-button @click="send_contact_form"  class="left20" data-disable-with="Send Message" >Send Message</ion-button>-->
                            </div>
                        </div>
                    </div>
                </div>
              <div v-if="showSectionComandaPrepaid" id="showSectionComandaPrepaid" class="sectionComandaPrepaid border0">
                    <div class="divTitleComandaPrepaid orange_bar_gradient padding_lr_10">
                        <div class="font27 bellowtop5 ">
                            {{t.btn_order}} <span v-if="prepaidSelected" id="titleComanda" class="colorWhite">PREPAID</span> <span v-if="!prepaidSelected" id="titleComanda" class="colorWhite">POSTPAID</span>
                        </div>
                    </div>
                    <div id="divInformationPrepaid" class="flex-column padding_lr_10 " >
                        <ion-radio-group v-model="command.formaJuridica" >
                            <div  id="divAlegePfsauPJ" class="flex-row top30 align-items-center flex-center ">
                                <div class="flex-row  align-items-center flex-center">
                                    <ion-radio slot="start" v-bind:value="persoanaFizica"></ion-radio>
                                    <span class="left5 top10">{{t.individual}}</span>
                                </div>
                                <div class="flex-row  align-items-center flex-center left30">
                                    <ion-radio slot="start" v-bind:value="persoanaJuridica" ></ion-radio>
                                   <span class="left5 top10">{{t.company}}</span>
                                </div>
                            </div>
                        </ion-radio-group>
                        <div id="sumar_comanda_prepaid">
                            <div v-if="detalii_comanda_prepaid" class="p-3">
                            </div>
                        </div>
                        <div class="flex-row flex-justify-content-space-between top20">
                            <div class="divAdreseFacturareSiLivrare  top30  width100 ">
                                <div id="divAdresaFacturare" class="flex-column divAdresaFacturare flex1 ">
                                    <div class="divAdresaFacturareTitlu width100 div_text_center font22">{{t.invoice_delivery_address}}</div>
                                    <div v-if="command.formaJuridica=='persoanaJuridica'">
                                        <div class="divAdresaFacturareCompanyName flex-row padding_10 flex-justify-content-space-evenly align-items-center">
                                            <div  class="flex2">{{t.company_name}}<sup>*</sup></div>
                                            <div class="flex3">
                                                <input id="companyName" class="width100"  v-model="command.company_name" /></div>
                                        </div>
                                        <div class="divAdresaFacturareCompanyRegNr flex-row padding_10 flex-justify-content-space-evenly align-items-center">
                                            <div class="flex2">{{t.company_registration_number}} <br><span class="font12">({{t.registry_of_commerce}})<sup>*</sup></span></div>
                                            <div class="flex3">

                                                <input id="nrRegComert" class="width100" required="required"  v-model="command.company_registration_nr" /></div>
                                        </div>
                                        <div class="divAdresaFacturareCompanyCui flex-row padding_10 flex-justify-content-space-evenly align-items-center">
                                            <div class="flex2">{{t.company_cui}}<sup>*</sup></div>
                                            <div class="flex3">
                                                <input id="cui" class="width100" required="required"  v-model="command.company_cui" /></div>
                                        </div>
                                        <div class="divAdresaFacturareCompanyCui flex-row padding_10 flex-justify-content-space-evenly align-items-center">
                                            <div class="flex2">{{t.bank_name}}</div>
                                            <div class="flex3">
                                                <input id="cui" class="width100" required="required"  v-model="command.bank" /></div>
                                        </div>
                                        <div class="divAdresaFacturareCompanyCui flex-row padding_10 flex-justify-content-space-evenly align-items-center">
                                            <div class="flex2">{{ t.bank_account }}</div>
                                            <div class="flex3">
                                                <input id="cui" class="width100" required="required"  v-model="command.iban" /></div>
                                        </div>
                                        <div id="divNumeDelegatPJ" class="divAdresaFacturareNumeDelegat  flex-row padding_10 flex-justify-content-space-evenly align-items-center">
                                            <div id="adresaFacturareLabelNumeDelegat" class="flex2">{{t.company_representative_name}}</div>
                                            <div class="flex3">
                                                <input id="representativeNamePJ" class="width100"  v-model="command.company_representative_name" /></div>
                                        </div>
                                    </div>
                                    <div class="divAdresaFacturareEmail flex-row padding_10 flex-justify-content-space-evenly align-items-center">
                                        <div class="flex2">Email<sup>*</sup></div>
                                        <div class="flex3">
                                            <input id="company_email" class="width100" required="required"  v-model="command.company_email" /></div>
                                    </div>
                                    <div class="divAdresaFacturareTelefon flex-row padding_10 flex-justify-content-space-evenly align-items-center">
                                        <div class="flex2">{{t.company_phone}}<sup>*</sup></div>
                                        <div class="flex3">
                                            <input id="company_phone" class="width100" required="required"  v-model="command.company_phone" /></div>
                                    </div>
                                    <div v-if="command.formaJuridica=='persoanaFizica'"  id="divAdresaFacturareNumePF" class="divAdresaFacturareAdresa flex-row padding_10 flex-justify-content-space-evenly align-items-center ">
                                        <div  id="divAdresaFacturareLabelNumePF" class="flex2 ">{{t.company_representative_name}}<sup>*</sup> </div>
                                        <div class="flex3 width100">
                                            <input id="representativeNamePF" class="width100" required="required"  v-model="command.company_representative_name" /></div>
                                    </div>
                                    <div class="divAdresaFacturareAdresa flex-row padding_10 flex-justify-content-space-evenly align-items-center">
                                        <div class="flex2">{{t.company_address}}<sup>*</sup></div>
                                        <div class="flex3 width100">
                                            <input id="companyAddress" class="width100 baba" required="required"  v-model="command.company_address" /></div>
                                    </div>
                                    <div class="divAdresaFacturareAdresa flex-row padding_10 flex-justify-content-space-evenly align-items-center">
                                        <div class="flex2">{{t.company_postal_code}}Cod postal</div>
                                        <div class="flex3 width100">
                                            <input id="companyPostalCode" class="width100" required="required"  v-model="command.company_postal_code" /></div>
                                    </div>
                                    <div class="divAdresaFacturareOras flex-row padding_10 flex-justify-content-space-evenly align-items-center">
                                        <div class="flex2">{{t.company_city}}<sup>*</sup></div>
                                        <div class="flex3">
                                            <input id="companyCity" class=" width100" required="required"  v-model="command.company_city" /></div>
                                    </div>
                                    <div class="divAdresaFacturareJudet flex-row padding_10 flex-justify-content-space-evenly align-items-center">
                                        <div class="flex2">{{t.company_county}}<sup>*</sup></div>
                                        <div class="flex3">
                                            <input id="companyCounty" class="width100" required="required"  v-model="command.company_county" /></div>
                                    </div>
                                    <div class="divAdresaFacturareJudet flex-row padding_10 flex-justify-content-space-evenly align-items-center">
                                        <div class="flex2">{{t.company_country}}</div>
                                        <div class="flex3">
                                            <input id="companyCounty" class="width100" required="required"  v-model="command.company_country" /></div>
                                    </div>
                                </div>
                                <div id="rightSideDelivery">
                                    <ion-radio-group v-model="another_delivery_address" >
                                        <div  id="divAlegePfsauPJ" class="flex-row align-items-center top30 flex-center ">
                                            <div>
                                                <ion-radio slot="start" v-bind:value="false" ></ion-radio>
                                                {{t.same_delivery_adddress_as_billing}}
                                            </div>
                                            <div class="left10">
                                                <ion-radio slot="start" v-bind:value="true"></ion-radio>
                                                {{t.other_delivery_adddress}}
                                            </div>
                                        </div>
                                    </ion-radio-group>
                                    <div v-if="showOtherDeliveryAddress" id="divAdresaLivrare" class="flex-column hidden left20">
                                        <div class="divAdresaLivrareName flex-row padding_10 flex-justify-content-space-evenly align-items-center">
                                            <div class="flex1">{{t.delivery_name}}<sup>*</sup></div>
                                            <div class="flex3">
                                                <input ref="delivery_name" @click="selectContentDeliveryName" id="deliveryName" class="width100"  v-model="command.delivery_name" /></div>
                                        </div>
                                        <div class="divAdresaLivrareAdresa flex-row padding_10 flex-justify-content-space-evenly align-items-center">
                                            <div class="flex1">{{t.delivery_address}}<sup>*</sup></div>
                                            <div class="flex3">
                                                <input  ref="delivery_address" @click="selectContentDeliveryAddress" id="deliveryAddress" class="width100" v-model="command.delivery_address" /></div>
                                        </div>
                                        <div class="divAdresaLivrareCity flex-row padding_10 flex-justify-content-space-evenly align-items-center">
                                            <div class="flex1">{{t.delivery_city}}<sup>*</sup></div>
                                            <div class="flex3">
                                                <input  ref="delivery_city" @click="selectContentDeliveryCity" id="deliveryCity" class="width100" v-model="command.delivery_city" /></div>
                                        </div>
                                        <div class="divAdresaLivrareJudet flex-row padding_10 flex-justify-content-space-evenly align-items-center">
                                            <div class="flex1">{{t.delivery_county}}<sup>*</sup></div>
                                            <div class="flex3">
                                                <input  ref="delivery_county" @click="selectContentDeliveryCounty" id="comanda_delivery_county" class="width100" v-model="command.delivery_county" /></div>
                                        </div>
                                        <div class="divAdresaLivrareJudet flex-row padding_10 flex-justify-content-space-evenly align-items-center">
                                            <div class="flex1">{{t.delivery_country}}<sup>*</sup></div>
                                            <div class="flex3">
                                                <input  ref="delivery_county" @click="selectContentDeliveryCountry" id="comanda_delivery_county" class="width100" v-model="command.delivery_country" /></div>
                                        </div>
                                        <div class="divAdresaFacturareAdresa flex-row padding_10 flex-justify-content-space-evenly align-items-center">
                                            <div class="flex2">{{t.delivery_postal_code}}</div>
                                            <div class="flex3 width100">
                                                <input id="companyPostalCode" @click="selectContentDeliveryPostalCode" class="width100" required="required"  v-model="command.delivery_postal_code" /></div>
                                        </div>

                                        <div class="divAdresaLivrareEmail flex-row padding_10 flex-justify-content-space-evenly align-items-center">
                                            <div class="flex1">{{ t.delivery_email }}<sup>*</sup></div>
                                            <div class="flex3">
                                                <input  ref="delivery_email" @click="selectContentDeliveryEmail" id="comanda_delivery_email" class="width100" v-model="command.delivery_email" /></div>
                                        </div>
                                        <div class="divAdresaLivrareTelefon flex-row padding_10 flex-justify-content-space-evenly align-items-center">
                                            <div class="flex1">{{ t.delivery_phone }}<sup>*</sup></div>
                                            <div class="flex3">
                                                <input  ref="delivery_phone" @click="selectContentDeliveryPhone" id="comanda_delivery_phone" class="width100" v-model="command.delivery_phone" /></div>
                                        </div>
                                    </div>
                                    <div id="agreementSection">
                                        <div class="top20 left20 ">
                                            <div class="left30 top20">
                                                <input type="checkbox" v-model="tcg" id="acord_tcg" value="1" class="form-check-input font2rem" />
                                                <span class="form-check-label " >{{t.i_agree_with}} <a  target="_blank" rel="noreferrer" class="blue" :href="prepaidSelected?'/docs/Termeni_si_conditii_de_utilizare_a_Cartelei_Next_01_06_2023.pdf':'/docs/Termeni_si_Conditii_Generale_NextM2M_postpaid_01_06_2023.pdf'"> {{t.general_terms_and_conditions}}(TCG) </a></span>
                                            </div>
                                            <div class="left30 top20">
                                                <input type="checkbox" v-model="gdpr" id="acord_gdpr" value="1" class="form-check-input font2rem" />
                                                <span class="form-check-label"> {{t.i_agree_with}}  <a  class="blue" href="./docs/Politica_de_confidentialitate_GDPR_NextM2M_01_06_2023.pdf">GDPR </a></span>
                                            </div>
                                        </div>
                                        <div v-if="prepaidSelected" class="divTrimiteComanda div_text_center width80 top30 ">
                                            <button id="btnComanda" class="btnComanda" title="" @click="recaptcha">{{t.go_to_payment}}</button>
<!--                                            <button id="btnComanda" class="btnComanda" title="" @click="checkInfoAndAgreementAndPlaceOrder">Plata</button>-->
                                        </div>
                                    </div>
                                    <div v-if="!prepaidSelected" class="divTrimiteComanda div_text_center width80 top30 ">
                                        <button id="btnComanda" class="btnComanda" title="" @click="recaptcha">{{t.send_order}}</button>
                                    </div>
                                </div>
                                <div style="height:30px"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>

            <div class="height30"></div>



          <div  v-if="!is_mobile && show_section_footer_desktop" id="sectionFooterDesktop" class="max1000 top40" style="position:relative;top:0px">
            <div class="maxWidth1900 width100  border0">
              <div class="flex-row whiteLink flex-justify-content-space-evenly align-items-start footer padding_10 width100 ">
                <div  id="divFooterDespreContent" class="">
                  <h6 class="orangeTitle">Date Companie</h6>
                  <div class="colorWhite  font14">
                    <h6>
                      Veridian Systems SRL
                    </h6>
                    <div class="colorWhite  font14">
                      Strada Văliug nr. 32, Sector 1, Bucuresti <br>
                      E-mail:  office@veridian.ro<br>
                      <a href="http://www.veridian.ro" class="colorWhite">www.veridian.ro</a>
                    </div>

                  </div>
                </div>
                <div id="divLegalContent" class="font12">
                  <h6 class="orangeTitle">LEGAL</h6>
                  <div></div>
                  <div>
                    <a target="_blank" class="colorBlack font14" href="/docs/Politica_de_confidentialitate_GDPR_NextM2M_01_06_2023.pdf">Politica de confidențialitate (GDPR)</a>
                  </div>

                  <div>
                    <a target="_blank" class="colorBlack font14" href="/docs/Politica_de_utilizare_Cookies_NextM2M_01_06_2023.pdf">Politica de utilizare Cookies</a>
                  </div>
                  <div></div>
                  <div>
                    <a target="_blank" class="colorBlack font14" href="/docs/Clauze_achizitionare_servicii_online_Next-M2M_01_06_2023.pdf">Clauze achiziționare servicii on-line</a>
                  </div>
                  <div></div>
                  <div>
                    <a target="_blank" class="colorBlack font14" href="/docs/Procedura_de_solutionare_a_reclamatiilor_Next-M2M_01_06_2023.pdf">Procedura de soluţionare a reclamaţiilor</a>
                  </div>
                  <div>
                    <a target="_blank" class="colorBlack font14" href="/docs/Cerere_de_portare_Next_M2M_01_06_2023.pdf">Cerere de portare Next-M2M</a>
                  </div>

                </div>

                <div class="divServicii">
                  <h6 class="orangeTitle ">SERVICII</h6>
                  <h6 class="orangerTitle ">ABONAMENTE</h6>
                  <div>
                    <a target="_blank" class="colorBlack font14" href="/docs/Termeni_si_Conditii_Generale_NextM2M_postpaid_01_06_2023.pdf">Termeni și Condiții Generale Postpaid</a>
                  </div>
                  <div>
                    <a target="_blank" class="colorBlack font14" href="/docs/Clauze_contractuale_Principale_NextM2M_postpaid_iunie_2023.pdf">Clauze Contractuale Principale</a>
                  </div>

                  <h6 class="orangerTitle ">Cartela Next-M2M</h6>
                  <div>
                    <a target="_blank" class="colorBlack font14" href="/docs/Termeni_si_conditii_de_utilizare_a_Cartelei_Next_01_06_2023.pdf">Termeni și Condiții Generale Prepaid</a>
                  </div>

                </div>

                <div id="divUtileContent" class=" ">
                  <div  class="left30  lnkContact orangeTitle"><h6>UTILE</h6></div>

                  <div>
                    <a href="https://www.telekom.ro/harta-acoperire/" class="colorBlack font14" target="_blank">Acoperire</a>
                  </div>

                  <div>
                    <a href="https://www.telekom.ro/parametri-de-calitate-internet" class="colorBlack font14" target="_blank">Parametrii de calitate</a>
                  </div>

                  <div>
                    <a href="http://www.anpc.gov.ro/" class="colorBlack font14" target="_blank">ANPC</a> <span class="colorWhite"> şi </span> <a href="https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=RO" class="colorBlack font14" target="_blank">Platforma SOL</a>
                  </div>

                  <div>
                    <a href="https://www.ancom.org.ro/" class="colorBlack font14" target="_blank">ANCOM</a>
                  </div>

                  <div>
                    <a href="https://www.netograf.ro" class="colorBlack font14" target="_blank">Netograf</a>
                  </div>

                  <div v-if="false">
                    <a href="#" class="colorBlack font14" target="_blank">Portare</a>
                  </div>
                </div>

              </div>

            </div>
          </div>

          <div  v-if="is_mobile  && show_section_footer_mobile" id="sectionFooterMobile" class="max1000" style="position:relative;top:0px">
            <div class="ion-margin ion-padding footer">
                <div  id="divFooterDespreContent" class="">
                  <h6 class="orangeTitle">Date Companie</h6>
                  <div class="colorWhite  font16 ">
                    <h6>
                      Veridian Systems SRL
                    </h6>
                    <div class="colorWhite  font16 ">
                      Strada Văliug nr. 32, Sector 1, Bucuresti <br>
                      E-mail:  office@veridian.ro<br>
                      <a href="http://www.veridian.ro" class="colorWhite">www.veridian.ro</a>
                    </div>

                  </div>
                </div>

                <div id="divLegalContent" class="font12">
                  <h6 class="orangeTitle">LEGAL</h6>

                  <div></div>
                  <div class="top10">
                    <a target="_blank" class="colorBlack font16 " href="./docs/Politica_de_confidentialitate_GDPR_NextM2M_01_06_2023.pdf">Politica de confidențialitate (GDPR)</a>
                  </div>

                  <div class="top10">
                    <a target="_blank" class="colorBlack font16 " href="./docs/Politica_de_utilizare_Cookies_NextM2M_01_06_2023.pdf">Politica de utilizare Cookies</a>
                  </div>
                  <div></div>
                  <div class="top10">
                    <a target="_blank" class="colorBlack font16 " href="./docs/Clauze_achizitionare_servicii_online_Next-M2M_01_06_2023.pdf">Clauze achiziționare servicii on-line</a>
                  </div>
                  <div></div>
                  <div class="top10">
                    <a target="_blank" class="colorBlack font16 " href="./docs/Procedura_de_solutionare_a_reclamatiilor_NextM2M_01_06_2023.pdf">Procedura de soluţionare a reclamaţiilor</a>
                  </div>

                </div>

                <div class="divServicii">
                  <h6 class="orangeTitle ">SERVICII</h6>
                  <h6 class="orangerTitle ">ABONAMENTE</h6>
                  <div class="top10">
                    <a target="_blank" class="colorBlack font16 " href="./docs/Termeni_si_Conditii_Generale_NextM2M_postpaid_01_06_2023.pdf">Termeni și Condiții Generale Postpaid</a>
                  </div>
                  <div class="top10">
                    <a target="_blank" class="colorBlack font16 " href="./docs/Clauze_contractuale_Principale_NextM2M_postpaid_iunie_2023.pdf">Clauze Contractuale Principale</a>
                  </div>

                  <h6 class="orangerTitle ">Cartela Next-M2M</h6>
                  <div class="top10">
                    <a target="_blank" class="colorBlack font16 " href="./docs/Termeni_si_conditii_de_utilizare_a_Cartelei_Next_01_06_2023.pdf">Termeni și Condiții Generale Prepaid</a>
                  </div>

                </div>


              <div id="divUtileContent" class=" ">
                <div  class="left30  lnkContact orangeTitle"><h6>UTILE</h6></div>
                  <div class="top10">
                    <a href="https://www.telekom.ro/harta-acoperire/" class="colorBlack font16 " target="_blank">Acoperire</a>
                  </div>

                  <div class="top10">
                    <a href="https://www.telekom.ro/parametri-de-calitate-internet" class="colorBlack font16 " target="_blank">Parametrii de calitate</a>
                  </div>

                  <div class="top10">
                    <a href="http://www.anpc.gov.ro/" class="colorBlack font16 " target="_blank">ANPC</a> <span class="colorWhite"> şi </span> <a href="https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=RO" class="colorBlack font16 " target="_blank">Platforma SOL</a>
                  </div>

                  <div class="top10">
                    <a href="https://www.ancom.org.ro/" class="colorBlack font16 " target="_blank">ANCOM</a>
                  </div>

                  <div class="top10">
                    <a href="https://www.netograf.ro" class="colorBlack font16 " target="_blank">Netograf</a>
                  </div>

                  <div class="top10">
                    <a href="#" class="colorBlack font16 " target="_blank">Portare</a>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

  <script>

    import {useStore} from "vuex";
    import axios from "axios";
    import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'
    import {addCircleOutline, removeCircleOutline, closeCircleOutline,refreshCircleOutline,menuOutline} from 'ionicons/icons';
    import {
        IonPage,
        IonHeader,
        IonToolbar,
        IonContent,
        isPlatform,
        // IonMenu,
        // IonMenuToggle,
        // IonMenuButton,
        // // IonButtons,
        IonTitle,
        IonRadio,
        IonRadioGroup,
        IonIcon,
        IonSelect,
        IonSelectOption,
        IonTextarea,
        IonPopover,
        // IonCard,
        // IonCardHeader,
        // IonCardTitle,
        // IonCardContent,
        // IonList,
        // IonItem,
        // IonLabel,
        IonButton,
        IonInput, alertController
    } from '@ionic/vue';
    import {log, presentAlertMessage, setLocalImageUrl} from "@/composables/useLog";
    import {ref, computed, onMounted, onBeforeMount} from "vue";
    import { useBackButton } from '@ionic/vue';
    import {useRouter,useRoute} from "vue-router";
    import {BASE_URL} from "@/composables/url_config";
    import {changeLanguage,
      languagePopoverOpen,
      t,
      openLanguagePopover,
      openLanguageMobilePopover,
      roSelected,
      enSelected,
      bgSelected,
      geSelected,
      frSelected,
      spSelected,
      itSelected,
      ruSelected,
      cnSelected,
      trSelected} from "@/composables/useLanguages";
    import LanguagesPopover from "@/components/LanguagesPopover.vue";
    export default {
      methods: {setLocalImageUrl},
        components: {
          LanguagesPopover,
          IonPage,
          IonHeader,
          // IonCard,
          // IonCardHeader,
          // IonCardTitle,
          // IonCardContent,
          IonContent,
          IonToolbar,
          // IonMenuButton,
          IonIcon,
          // IonButtons,
          IonTitle,
          // IonMenu,
          //   IonMenuToggle,
          IonRadio,
          IonRadioGroup,
            IonSelect,
            IonSelectOption,
            IonTextarea,
          IonPopover,
          // IonList,
          // IonItem,
          // IonLabel,
          IonButton,
          // HeaderBackToolbar,
          IonInput

        },

        setup() {
          log("welcome to setup")
          const router=useRouter()
          const route = useRoute();
          const store = useStore()
          const versionCode=ref (null)
          const versionName=ref (null)
          const showDivInfoCookies=ref (true)
          const show_section_contact=ref (false)
          const show_section_tariffs=ref (false)
          const show_section_aboutNextM2M=ref (false)
          const show_topup_prepaid_package=ref (false)
          const showSectionCarduriPrepaidOrPostpaid=ref (true)
          const showSectionComandaPrepaid=ref (false)
          const showSectionComandaPostpaid=ref (true)
          const show_topup_voucher_desktop=ref (false)
          const show_topup_cc_desktop=ref (true)
          const show_topup_voucher_mobile=ref (false)
          const showSectionPachetePrepaidDisponibile=ref (true)
          const showSectionPachetePostpaidDisponibile=ref (true)
          const prepaidSelected=ref (true)
          const nr_of_prepaid_sims=ref (1)
          // const showOtherDeliveryAddress=ref (false)
          const another_delivery_address=ref (false)
          const persoanaJuridica=ref ("persoanaJuridica")
          const persoanaFizica=ref ("persoanaFizica")
          const is_mobile=ref (false)
          const showMainContent=ref (true)
          const delivery_name=ref (null)
          const delivery_address=ref (null)
          const delivery_city=ref (null)
          const delivery_county=ref (null)
          const delivery_country=ref (null)
          const delivery_postal_code=ref (null)
          const delivery_email=ref (null)
          const delivery_phone=ref (null)
          const token=ref (null)
          const total_cost_package_and_trafic=ref (0)
          const transport_cost=ref (15)
          const euro=ref (5)
          const cost_prepaid_sim=ref (5)
          const cost_postpaid_sim=ref (0.6)
          const cost_postpaid_abonament_sim=ref (0.8)
          const tarif_date_prepaid=ref (10)
          const minim_contractual_period=ref ("2 ani")
          const tarif_date_postpaid=ref (8)
          const abonament_postpaid=ref (0.8)
          const tarif_sms_postpaid=ref (0.06)
          const nr_of_postpaid_sims=ref (10)
          const minim_nr_of_postpaid_sims=ref (10)
          const discountPrepaid=ref (0)
          const discountPostpaid=ref (0)
          const costPostpaidSubscription=ref (0.8)
          const validity_prepaid_sim=ref ("6 luni")
          const showRechargingOptionPrepaid=ref (true)
          const command=ref ({
                "company_name":"",
                "company_registration_nr":"",
                "company_cui":"",
                "company_representative_name":"",
                "company_address":"",
                "company_postal_code":"",
                "company_city":"",
                "company_county":"",
                "company_country":"",
                "company_email":"",
                "company_phone":"",
                "formaJuridica":"persoanaFizica",
                "delivery_name":"",
                "delivery_address":"",
                "delivery_city":"",
                "delivery_country":"",
                "delivery_county":"",
                "delivery_postal_code":"",
                "delivery_email":"",
                "delivery_phone":"",
                "bank":"",
                "iban":""
})
          const detalii_comanda_prepaid=ref(null)
          const showSpinner=ref (false)
          const showInfoCookies=ref (true)
          const showMainSection=ref (true)
          const showCeleMaiAccesibile=ref (true)
          const showMenu=ref (false)
          const postSuccess=ref (false)
          const tcg=ref (false)
          const gdpr=ref (false)
          const formUrl=ref ("")
          const show_section_footer_desktop=ref (true)
          const show_section_footer_mobile=ref (false)
          const show_section_recharge_mobile=ref (false)
          const show_section_recharge_desktop=ref (false)
          const show_topup_cc_mobile=ref (true)
          const showTopMenuRecharge=ref (false)
          const selectedRechargeAmount=ref (5)
          const enteredMsisdn=ref (null)
          const enteredEmail=ref (null)
          const topup_voucher_front_page_msisdn=ref (null)
          const topup_voucher_front_page_voucher=ref (null)
          const is_admin=ref (true)
          const contact_name=ref (null)
          const contact_email=ref (null)
          const contact_phone=ref (null)
          const contact_text=ref (null)
          const debug=ref (false)

          document.title = 'Next-M2M';

          const recharging_values=ref (
              {
                1: {value: 0.1, validity: '1 zi', traffic: '10 MB'},
                5: {value: 5, validity: '6', traffic: '500 MB'},
                10: {value: 10, validity: '1', traffic: '1 GB'},
                15: {value: 15, validity: '2', traffic: '1.5 GB'},
                20: {value: 20, validity: '3', traffic: '2 GB'},
                25: {value: 25, validity: '4', traffic: '2.5 GB'},
                30: {value: 30, validity: '5', traffic: '3 GB'},
              }
              )

          versionCode.value=store.getters.getVersionCode
          versionName.value=store.getters.getVersionName

            const siteKey = computed(() => {
                return '6Le1RdQUAAAAAM22byaAYDeHEkvI6A23xCQwFNet';
            });

            const handleError = () => {
                alert(333)
            };

            const handleSuccess = (response) => {
               alert(response)
            };







            const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
            const recaptcha = async () => {
                log("welcome in recaptcha!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
                await recaptchaLoaded()
                // Execute reCAPTCHA with action "login".
                const token = await executeRecaptcha('login')
               if(token){
                 log("am primit token:"+token)
                   checkInfoAndAgreementAndPlaceOrder()
               }
                else{
                    alert("error recaptcha token!")
               }
            }



            const recaptchaContact = async () => {
                log("welcome in recaptchaContact!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
                await recaptchaLoaded()
                // Execute reCAPTCHA with action "login".
                const token = await executeRecaptcha('login')
               if(token){
                 log(token)
                   send_contact_form()
               }
                else{
                    presentAlertMessage("error recaptcha token!")
               }
            }
          const recaptchaSendCommand = async () => {
                log("welcome in recaptchaContact!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
                await recaptchaLoaded()
                // Execute reCAPTCHA with action "login".
                const token = await executeRecaptcha('login')
               if(token){
                 log(token)
                   send_contact_form()
               }
                else{
                    presentAlertMessage("error recaptcha token!")
               }
            }

          // useBackButton(10, () => {
          //   router.push({name:"home"})
          //   const page = 'home'; // Set the desired page parameter value
          //   router.push({ name: 'home', params: {page:page} });
          // });

          if (isPlatform("desktop")) {
            is_mobile.value=false
            command.value.is_mobile=false
          }
          else if (isPlatform("mobile")) {
            is_mobile.value=true
            command.value.is_mobile=true

          }


          const acceptCookies=()=>{
              localStorage.setItem('agreedCookies','true')
              showInfoCookies.value=false
          }
          const closeShowInfoCookies=()=>{
              showInfoCookies.value=false
          }

          const activatePrepaid=()=>{
              goToHome()
            prepaidSelected.value=true
            showSectionPachetePrepaidDisponibile.value=true
            showSectionPachetePostpaidDisponibile.value=false
            showSectionComandaPostpaid.value=false
            showSectionComandaPrepaid.value=false
          }

          const activatePostpaid=()=>{
             goToHome()
            prepaidSelected.value=false
            showSectionPachetePrepaidDisponibile.value=false
            showSectionPachetePostpaidDisponibile.value=true
              showSectionComandaPostpaid.value=false
              showSectionComandaPrepaid.value=false
          }

          const computeDiscountPrepaid=(param_nr_of_sims)=>{
              log("intram la computeDiscountPrepaid cu param_nr_of_sims="+param_nr_of_sims)
            let computedDiscountPrepaid=0
            switch (true) {
              case param_nr_of_sims >= 500:
                  log("am intrat pe 100")
                computedDiscountPrepaid = 15;
                break;
              case param_nr_of_sims >= 100:
                  log("am intrat pe 100")
                computedDiscountPrepaid = 10;
                break;
              case param_nr_of_sims >= 50:
                  log("am intrat pe 50")
                computedDiscountPrepaid = 7;
                break;
              case param_nr_of_sims >= 20:
                  log("am intrat pe 20")
                computedDiscountPrepaid = 5;
                break;
              case param_nr_of_sims >= 10:
                  log("am intrat pe 20")
                computedDiscountPrepaid = 3;
                break;
              case param_nr_of_sims >= 0:
                  log("am intrat pe 00")
                computedDiscountPrepaid = 0;
                break;

              default:
                  log("am intrat pe 0")
                computedDiscountPrepaid = 0;
            }
            discountPrepaid.value=computedDiscountPrepaid
            log("discountPrepaid="+discountPrepaid.value)
            return computedDiscountPrepaid
          }
          const computeDiscountPostpaid=(param_nr_of_sims)=>{
              log("intram la computeDiscountPostpaid cu param_nr_of_sims="+param_nr_of_sims)
            let computedDiscountPostpaid=0
            switch (true) {
              case param_nr_of_sims >= 2000:
                  log("am intrat pe 2000")
                computedDiscountPostpaid = 20;
                break;
              case param_nr_of_sims >= 1000:
                  log("am intrat pe 1000")
                computedDiscountPostpaid = 15;
                break;
              case param_nr_of_sims >= 500:
                  log("am intrat pe 500")
                computedDiscountPostpaid = 10;
                break;
              case param_nr_of_sims >= 100:
                  log("am intrat pe 100")
                computedDiscountPostpaid = 5;
                break;
              default:
                  log("am intrat pe 0")
                computedDiscountPostpaid = 0;
            }
            discountPostpaid.value=computedDiscountPostpaid
            log("discountPostpaid="+discountPostpaid.value)
            return computedDiscountPostpaid
          }

          const totalCommandAmountPrepaid = computed(() => {
              log("calculam totalCommandAmountPrepaid")
              return (nr_of_prepaid_sims.value * cost_prepaid_sim.value*(100 - computeDiscountPrepaid(nr_of_prepaid_sims.value))/100)

          })

          const totalCommandAmountPostpaid = computed(() => {
            log("calculam totalCommandAmountPostpaid")
              return (nr_of_postpaid_sims.value * cost_postpaid_sim.value).toFixed(2)
          })

          const totalCostSubscriptionPostpaid = computed(() => {
            log("calculam totalCostSubscriptionPostpaid")
            log(computeDiscountPostpaid(nr_of_postpaid_sims.value))
            log(nr_of_postpaid_sims.value)
            log(costPostpaidSubscription.value)
            log(nr_of_postpaid_sims.value*cost_postpaid_abonament_sim.value)
              return (nr_of_postpaid_sims.value*cost_postpaid_abonament_sim.value*(100 - computeDiscountPostpaid(nr_of_postpaid_sims.value))/100).toFixed(2)
          })

          const increaseNrOfPrepaidSim = () => {
              nr_of_prepaid_sims.value ++
          }
          const decreaseNrOfPrepaidSim = () => {
              if(nr_of_prepaid_sims.value>=1){
                  nr_of_prepaid_sims.value --
              }
              else{
                presentAlertMessage("Introduceți numărul de SIM-uri dorite.")
                nr_of_prepaid_sims.value=1
              }
          }
          const increaseNrOfPostpaidSim = () => {
            if(nr_of_postpaid_sims.value<5000) {
              nr_of_postpaid_sims.value++
              costPostpaidSubscription.value+=cost_postpaid_abonament_sim.value
            }
          }
          const decreaseNrOfPostpaidSim = () => {
              if(nr_of_postpaid_sims.value>10){
                  nr_of_postpaid_sims.value --
                costPostpaidSubscription.value-=cost_postpaid_abonament_sim.value
              }
              else{
                presentAlertMessage("Minim "+ minim_nr_of_postpaid_sims.value+" SIM-uri pentru postpaid")
              }
          }
          const totalAmountPrepaid = () => {
              return (1.19*(euro.value*(nr_of_prepaid_sims.value * cost_prepaid_sim.value*(100 - computeDiscountPrepaid(nr_of_prepaid_sims.value))/100)+transport_cost.value)).toFixed(2)
          }

          const totalAmountPostpaid = () => {
              return (1.19*(euro.value*(nr_of_postpaid_sims.value * cost_postpaid_sim.value*(100 - computeDiscountPostpaid(nr_of_postpaid_sims.value))/100)+transport_cost.value)).toFixed(2)
          }

          const totalAmountPrepaidEuro = () => {
              return ((nr_of_prepaid_sims.value * cost_prepaid_sim.value*(100 - computeDiscountPrepaid(nr_of_prepaid_sims.value))/100)).toFixed(2)
          }

          const totalAmountPostpaidEuro = () => {
              return (nr_of_postpaid_sims.value * cost_postpaid_sim.value).toFixed(2)
          }


          const set_nr_of_prepaid_sim = (value) => {
            log("set prepaid cards nr")
            nr_of_prepaid_sims.value=value
          }
         const set_nr_of_postpaid_sim = (value) => {
            nr_of_postpaid_sims.value=value
          }
        const cancel_contact_form = () => {
            contact_name.value=''
            contact_email.value=''
            contact_text.value=''
          }
        const send_contact_form = async () => {
            log("welcome in placeExistingUserNewCommand")
            if(!(contact_email.value || contact_phone.value)){
                presentAlertMessage("Avem nevoie de o metoda de contact.")
                return
            }
            else if(!contact_text.value){
                presentAlertMessage("Te rugam sa scrii mesajul pt Next-M2M.")
                return
            }
            try{
                showSpinner.value=true
                const headers= {
                    // "Authorization":`Bearer ${token.value}`,
                    "Content-Type": "application/json",
                }
                const postData = {contact_name:contact_name.value,contact_email:contact_email.value,contact_phone:contact_phone.value,contact_text:contact_text.value}
                const result= await axios.post(BASE_URL+'/api/v1/home/sendContactForm', postData, {headers: headers})
                log("result.data din axios")
                log(result.data)
                showSpinner.value=false
                if(result.data.error==true){
                    presentAlertMessage(result.data.message)
                    return
                } else{
                    postSuccess.value=true
                    presentAlertMessage(result.data.message)
                    contact_text.value=''
                    return result.data
                }

            }catch{
                log("alerta de eroare send_contact_form")
            }
        }

          const closeMainSections=()=>{
              show_section_footer_mobile.value=false
              showSectionPachetePrepaidDisponibile.value=false
              showSectionCarduriPrepaidOrPostpaid.value=false
              // showMainSection.value=false
              showCeleMaiAccesibile.value=false
              showSectionComandaPrepaid.value=false
              show_section_aboutNextM2M.value=false
              show_section_contact.value=false
              show_section_recharge_mobile.value=false
              show_section_recharge_desktop.value=false
              show_topup_voucher_mobile.value=false
              show_topup_voucher_desktop.value=false
              show_section_tariffs.value=false
          }

          const closeMenu=()=>{
             showMenu.value=false
            showMainContent.value=true
          }
          const goToLegal=()=>{
            goToPage()

            // alert(is_mobile.value)
            showMainSection.value=true
            if(is_mobile.value) {
              showMenu.value=false
              showMainContent.value=true
              closeMainSections()
              show_section_footer_mobile.value=true
            }
          }

         const routeToAboutNextM2M = () => {
               closeMainSections()
               show_section_aboutNextM2M.value=true
          }
           const goToAboutNextM2M = () => {
             goToPage()
             showMenu.value=false
             setTimeout(()=>{
               const page = 'about'; // Set the desired page parameter value
               router.push({ name: 'home', params: {page:page} });
             })
          }

           const goToProductsAndServices = () => {
             goToPage()
             setTimeout(()=>{
               const page = 'tariffs'; // Set the desired page parameter value
               router.push({ name: 'home', params: {page:page} });
             })
          }
          const routeToProductsAndServices = () => {
             closeMainSections()
             show_section_tariffs.value=true
          }

          const goToContact = () => {
            goToPage()
            setTimeout(()=>{
              const page = 'contact'; // Set the desired page parameter value
              router.push({ name: 'home', params: {page:page} });
            })

          }

          const goToHome = () => {
            goToPage()
            setTimeout(()=>{
              const page = 'home'; // Set the desired page parameter value
              router.push({ name: 'home', params: {page:page} });
            },100)
          }

          const routeToHome = () => {
            if(is_mobile.value) {
              // alert("home mobile")
              closeMainSections()
              showSectionPachetePrepaidDisponibile.value=true
              showSectionCarduriPrepaidOrPostpaid.value=true
              showMainSection.value=true
              showSectionCarduriPrepaidOrPostpaid.value=true
              showSectionPachetePrepaidDisponibile.value=true
              showSectionPachetePostpaidDisponibile.value=false
              showSectionComandaPrepaid.value=false
              showSectionComandaPostpaid.value=false
              prepaidSelected.value=true
              showCeleMaiAccesibile.value=true
            }
            else {
              // alert("home desktop")
              closeMainSections()
              showSectionPachetePrepaidDisponibile.value=true
              showSectionCarduriPrepaidOrPostpaid.value=true
              showMainSection.value=true
              showSectionCarduriPrepaidOrPostpaid.value=true
              showSectionPachetePrepaidDisponibile.value=true
              showSectionPachetePostpaidDisponibile.value=false
              showSectionComandaPrepaid.value=false
              showSectionComandaPostpaid.value=false
              prepaidSelected.value=true
            }
          }

          const routeToContact = () => {
            closeMainSections()
            showSectionCarduriPrepaidOrPostpaid.value=false
            show_section_contact.value=true
          }

          const goToRecharge = () => {
            goToPage()
            setTimeout(()=>{
              const page = 'recharge'; // Set the desired page parameter value
              router.push({ name: 'home', params: {page:page} });
            })
          }

          const routeToRecharge = () => {
            closeMainSections()
            if(is_mobile.value) {
              log("goToRecharge is_mobile.value=true")
              show_section_recharge_mobile.value=true
              show_topup_voucher_mobile.value=false

            }else{
              log("goToRecharge is_mobile.value=false")
              show_section_recharge_desktop.value=true
              show_topup_voucher_desktop.value=false
            }
          }

          const openMaineMenu=()=>{
            showMenu.value=!showMenu.value
            showMainContent.value=false
          }

          const goToPage=()=>{
            log("welcome in gotoPage cu ruta"+ route.params.page)
            showMenu.value=false
            showMainContent.value=true
            const page = route.params.page || 'home' ;
           if(page=='contact'){
             log(1)
             routeToContact()
           }
          else if(page=='tariffs'){
             log(2)
             routeToProductsAndServices()
           }
          else if(page=='home' || page=='' || page==undefined){
             log(3)
             routeToHome()
           }
          else if(page=='about'){
             log(4)
             routeToAboutNextM2M()
           }
          else if(page=='recharge'){
             log(5)
             routeToRecharge()
           }
           else{
             routeToHome()
           }
          }


          const selectContentDeliveryName = () => {
            delivery_name.value.select()
          }
          const selectContentDeliveryAddress = () => {
                      delivery_address.value.select()
                    }
          const selectContentDeliveryCity = () => {
                      delivery_city.value.select()
                    }
          const selectContentDeliveryCounty = () => {
                      delivery_county.value.select()
                    }
          const selectContentDeliveryCountry = () => {
                      delivery_country.value.select()
                    }
          const selectContentDeliveryPostalCode = () => {
                      delivery_postal_code.value.select()
                    }
          const selectContentDeliveryEmail = () => {
                      delivery_email.value.select()
                    }
          const selectContentDeliveryPhone = () => {
                      delivery_phone.value.select()
                    }
                    const selectContent = () => {

                    }

          const openOtherDeliveryAddress = () => {
              command.value.delivery_name=command.value.company_representative_name
              command.value.delivery_address=command.value.company_address
              command.value.delivery_city=command.value.company_city
              command.value.delivery_county=command.value.company_county
              command.value.delivery_country=command.value.company_country
              command.value.delivery_email=command.value.company_email
              command.value.delivery_phone=command.value.company_phone
              command.value.delivery_postal_code=command.value.company_postal_code
          }


            const showOtherDeliveryAddress=computed(()=>{
                if(another_delivery_address.value==true){
                    openOtherDeliveryAddress()
                    return true
                }else{
                    return false
                }
            })
         const checkInfoAndAgreementAndPlaceOrder = () => {
           if(!tcg.value){
             presentAlertMessage("Confirmati acordul TCG")
             return false
           }
           if(!gdpr.value){
             presentAlertMessage("Confirmati acordul GDPR")
             return true
           }
           if(!command.value.company_email){
             presentAlertMessage("Adresa de email obligatorie pentru confirmare")
             return true
           }
           if(!command.value.company_representative_name){
             presentAlertMessage("Numele obligatoriu pentru livrare")
             return true
           }
           if(!command.value.company_city){
             presentAlertMessage("Completati campurile obligatorii pentru livrare (orasul)")
             return true
           }
           if(!command.value.company_county){
             presentAlertMessage("Completati campurile obligatorii pentru livrare (judetul)")
             return true
           }
           if(!command.value.company_country){
             presentAlertMessage("Completati campurile obligatorii pentru livrare (tara)")
             return true
           }
           log(command.value)
             if(token.value)
             {
               log("am gasit token de la user registered")
                 placeExistingUserNewCommand()
             }
             else{
                 log("verificam prepaid sau nu")
                 if(prepaidSelected.value) {
                     // alert("prepaid cu new user")
                     showSpinner.value = true
                     placeNewUserNewCommand().then((result) => {
                         showSpinner.value = false
                           log("am primit formUrl=" + result.formUrl)
                         showSectionComandaPrepaid.value = false
                         showSectionCarduriPrepaidOrPostpaid.value = true
                         showSectionPachetePrepaidDisponibile.value = true
                         formUrl.value = result.formUrl
                         // showPopup()
                         openBT()
                     })
                }else{
                   log("am intrat pe else la if(prepaidSelected.value)")
                     showSpinner.value = true
                     placeNewUserNewCommand().then((result) => {
                       showSpinner.value = false
                       log("am primit formUrl=" + result.formUrl)
                       showSectionComandaPrepaid.value = true
                       showSectionCarduriPrepaidOrPostpaid.value = true
                       showSectionPachetePrepaidDisponibile.value = true
                       formUrl.value = result.formUrl
                       // showPopup()
                       openBT()
                     })
                  }
             }
        }
          const placeExistingUserNewCommand= async ( )=>{
                log("welcome in placeExistingUserNewCommand")
                log("verificam campurile si le completam")
              log(command.value)
              log('command.value.delivery_name==""?'+command.value.delivery_name=="")
              if(command.value.delivery_name==""){command.value.delivery_name=command.value.company_representative_name}
              log('am facut acum command.value.delivery_name='+command.value.delivery_name)
              if(command.value.delivery_address==""){command.value.delivery_address=command.value.company_address}
              if(command.value.delivery_city==""){command.value.delivery_city=command.value.company_city}
              if(command.value.delivery_county==""){command.value.delivery_county=command.value.company_county}
              if(command.value.delivery_country==""){command.value.delivery_country=command.value.company_country}
              if(command.value.delivery_postal_code==""){command.value.delivery_postal_code=command.value.company_postal_code}
              if(command.value.delivery_email==""){command.value.delivery_email=command.value.company_email}
              if(command.value.delivery_phone==""){command.value.delivery_phone=command.value.company_phone}
                try{
                    const headers= {
                        "Authorization":`Bearer ${token.value}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*"
                    }
                    const postData = {command:command.value}
                    const result= await axios.post(BASE_URL+'/api/v1/home/placeExistingUserNewCommand', postData, {headers: headers})
                    log("result.data din axios")
                    log(result.data)
                    if(result.data.error==true){
                        presentAlertMessage(result.data.message)
                        return
                    } else{
                        postSuccess.value=true
                        return result.data
                    }

                }catch{
                    log("alerta de eroare placeExistingUserNewCommand")
                }
            }

          const placeNewUserNewCommand= async ( )=>{
                log("welcome in placeNewUserNewCommand")
              log(command.value)
              if(command.value.delivery_name==""){command.value.delivery_name=command.value.company_representative_name}
              else{log("heeeeeyyyyyyy command.value.delivery_name="+command.value.delivery_name)}
              log('am facu tacum command.value.delivery_name='+command.value.delivery_name)
              if(command.value.delivery_address==""){command.value.delivery_address=command.value.company_address}
              if(command.value.delivery_city==""){command.value.delivery_city=command.value.company_city}
              if(command.value.delivery_county==""){command.value.delivery_county=command.value.company_county}
              if(command.value.delivery_country==""){command.value.delivery_country=command.value.company_country}
              if(command.value.delivery_postal_code==""){command.value.delivery_postal_code=command.value.company_postal_code}
              if(command.value.delivery_email==""){command.value.delivery_email=command.value.company_email}
              if(command.value.delivery_phone==""){command.value.delivery_phone=command.value.company_phone}
              log(command.value)
                try{
                    const headers= {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*"
                    }
                    showSpinner.value=true
                    const postData = {command:command.value}
                    const result= await axios.post(BASE_URL+'/api/v1/home/placeNewUserNewCommand.json', postData, {headers: headers})
                    log("result.data din axios")
                    log(result.data)
                    if(result.data.error==true){
                        presentAlertMessage(result.data.message)
                        return
                    } else{
                        showSpinner.value=false
                        postSuccess.value=true
                        log(result.data)
                        return result.data
                    }

                }catch{
                    log("alerta de eroare placeNewUserNewCommand")
                }
            }

          onBeforeMount(() => {
            let agreedCookies=localStorage.getItem('agreedCookies')
            log("agreedCookies"+agreedCookies)
            log("--------->> onBeforeMount")
            if(agreedCookies=='true'){
              showInfoCookies.value=false
            }else{
              showInfoCookies.value=true
            }

            goToPage()
          })

          onMounted(() => {

            });




            const openBT=()=>{
              log("deschidem o noua pagina catre formUrl.value="+formUrl.value )
                // window.open(formUrl.value, '_blank');
              location.href = formUrl.value;
            }
            const check_human=()=>{

            }


            const showPopup = async () => {
                const alert = await alertController.create({
                    header: 'Payment',
                    message: 'Deschideti pagina de plata BT?',
                    buttons: [
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: cancelPayment
                        },
                        {
                            text: 'Pay now',
                            cssClass: 'secondary',
                            handler: openBT
                        }
                    ]
                });
                alert.present();
            };


            const cancelPayment = () => {
                // Your code to handle cancel payment
            };
         const cancel_recharge_by_cc = () => {
                        // Your code to handle cancel payment
                    };
         const recharge_by_cc = async ( )=>{
                 log("welcome in recharge_by_cc")
             // "Authorization":`Bearer ${token.value}`,
                 try{
                     const headers= {
                         "Content-Type": "application/json",
                     }
                     const postData = {email:enteredEmail.value, currency:"euro", msisdn:enteredMsisdn.value, recharge_amount:selectedRechargeAmount.value,distributor_id:323}
                     log(postData)
                     const result= await axios.post(BASE_URL+'/api/v1/home/recharge_sim_online', postData, {headers: headers})
                     log("result.data din axios")
                     log(result.data)
                     if(result.data.error==true){
                         presentAlertMessage(result.data.message)
                         return
                     } else{
                         postSuccess.value=true
                         return result.data
                     }

                 }catch{
                     log("alerta de eroare ")
                 }
             }


         function call_recharge_by_cc(){
            showSpinner.value=true
             recharge_by_cc().then((result)=>{
             log("am primit formUrl="+result.formUrl)
             showSectionComandaPrepaid.value=false
             showSectionCarduriPrepaidOrPostpaid.value=true
             showSectionPachetePrepaidDisponibile.value=true
             formUrl.value=result.formUrl
             showSpinner.value=false
             // showPopup()
               openBT()
             })
         }

        const confirm_recharge_by_cc = async () => {
             log(selectedRechargeAmount.value)
          if(!enteredMsisdn.value){
            presentAlertMessage("Introduceți numărul Next-M2M pe care doriți să îl încărcați")
            return
          }
          else if(!enteredEmail.value){
            presentAlertMessage("Introduceți o adresa de email la care vom putea trimite confirmarea plății")
            return
          }
          else {
            let messageAlert = ''
            messageAlert = "Ati făcut o comandă de reincarcare a numarului " + enteredMsisdn.value + " cu un credit de " + selectedRechargeAmount.value + ' &euro;. '
            const alert = await alertController.create({
              header: 'Confirm',
              message: messageAlert,
              buttons: [
                {
                  text: 'Anulează',
                  role: 'cancel',
                  // handler: cancelCommand
                },
                {
                  text: 'Continuă',
                  cssClass: 'secondary',
                  handler: call_recharge_by_cc
                }
              ]
            });
            alert.present();
          }
            };

      const cancel_recharge_by_voucher = () => {
                              // Your code to handle  payment
                          };
      const recharge_by_voucher = () => {
                        // Your code to handle  payment
                    };

      const confirm_prepaid_command=()=>{
        if(nr_of_prepaid_sims.value<=0){
          presentAlertMessage("Alegeți numărul de SIM-uri")
          return
        }
          if(persoanaFizica.value==true){
              command.value.persoanaFizica=true
              command.value.persoanaJuridica=false
          }else{
              command.value.persoanaFizica=false
              command.value.persoanaJuridica=true
          }
          command.value.nr_of_sims=nr_of_prepaid_sims.value
          command.value.cost_sim=cost_prepaid_sim.value
          command.value.discountPrepaid=discountPrepaid.value
          command.value.prepaid=true
          command.value.order_type='sim'
          command.value.total_cost_package_and_trafic=totalAmountPrepaid()
          command.value.totalAmountPrepaid=totalAmountPrepaid()
          command.value.totalAmountPrepaidEuro=totalAmountPrepaidEuro()
          prepaidSelected.value=true
          showSectionCarduriPrepaidOrPostpaid.value=false
          showSectionPachetePrepaidDisponibile.value=false
          showSectionPachetePostpaidDisponibile.value=false
          showSectionComandaPrepaid.value=true
          showSectionComandaPostpaid.value=false

      }
      const confirm_postpaid_command=()=>{
        if(nr_of_postpaid_sims.value<10){
          presentAlertMessage("Minim " + minim_nr_of_postpaid_sims.value+" SIM-uri postpaid.")
          return
        }
          if(persoanaFizica.value==true){
              command.value.persoanaFizica=true
              command.value.persoanaJuridica=false
          }else{
              command.value.persoanaFizica=false
              command.value.persoanaJuridica=true
          }

          command.value.nr_of_sims=nr_of_postpaid_sims.value
          command.value.cost_sim=cost_postpaid_sim.value
          command.value.discountPrepaid=discountPrepaid.value
          command.value.prepaid=false
          command.value.order_type='sim'
          // command.value.total_cost_package_and_trafic=totalAmountPostpaid()
          command.value.total_cost_package_and_trafic=totalAmountPostpaid()
          command.value.totalAmountPostpaid=totalAmountPostpaid()
          command.value.totalAmountPostpaidEuro=totalAmountPostpaidEuro()
          // alert(command.value.total_cost_package_and_trafic)
          prepaidSelected.value=false
          showSectionCarduriPrepaidOrPostpaid.value=false
          showSectionPachetePrepaidDisponibile.value=false
          showSectionPachetePostpaidDisponibile.value=false
          showSectionComandaPrepaid.value=true
          // showSectionComandaPostpaid.value=true
      }

      const checkPostpaidMinMaxNrOfSims=()=>{
        log("checkPostpaidMinMaxNrOfSims")
        if(nr_of_postpaid_sims.value<=0){
          nr_of_postpaid_sims.value=null
        }
        if(nr_of_postpaid_sims.value>5000){
          nr_of_postpaid_sims.value=5000
        }

      }
    const checkPrepaidMinMaxNrOfSims=()=>{
        log("checkPrepaidMinMaxNrOfSims")
        if(nr_of_prepaid_sims.value<=0){
          nr_of_prepaid_sims.value=null
        }
        if(nr_of_prepaid_sims.value>5000){
          nr_of_prepaid_sims.value=5000
        }
      }
            const customFormatter=(inputLength, maxLength)=> {
                return `${maxLength - inputLength} charactere rămase`;
            }
            const login_under_construction=()=> {
               presentAlertMessage("IoT platform under maintenance")
                goToHome()
            }

          return {
            // IonPage,
            // IonMenu,
            // IonMenuButton,
            // IonButtons,
            closeCircleOutline,
              addCircleOutline,
              removeCircleOutline,
            refreshCircleOutline,
            versionCode,
            versionName,
            showDivInfoCookies,
            show_section_contact,
            show_section_tariffs,
            show_section_aboutNextM2M,
            show_topup_prepaid_package,
            showSectionCarduriPrepaidOrPostpaid,
            showSectionComandaPrepaid,
            showSectionComandaPostpaid,
            is_mobile,
            showMainContent,
            showSpinner,
            showInfoCookies,
            closeShowInfoCookies,
            show_topup_voucher_desktop,
            show_topup_cc_desktop,
            show_topup_voucher_mobile,
            showSectionPachetePrepaidDisponibile,
            showSectionPachetePostpaidDisponibile,
            prepaidSelected,
            activatePrepaid,
            activatePostpaid,
            nr_of_prepaid_sims,
              decreaseNrOfPrepaidSim,
              increaseNrOfPrepaidSim,
              increaseNrOfPostpaidSim,
              decreaseNrOfPostpaidSim,
            cost_prepaid_sim,
            discountPrepaid,
            computeDiscountPrepaid,
            totalCommandAmountPrepaid,
              totalCommandAmountPostpaid,
            validity_prepaid_sim,
            set_nr_of_prepaid_sim,
              set_nr_of_postpaid_sim,
            command,
            detalii_comanda_prepaid,
            persoanaJuridica,
            persoanaFizica,
            openOtherDeliveryAddress,
            another_delivery_address,
            showOtherDeliveryAddress,
            tcg,
            gdpr,
            checkInfoAndAgreementAndPlaceOrder,
            goToHome,
            goToAboutNextM2M,
            goToProductsAndServices,
            goToRecharge,
            goToContact,
            goToLegal,
            closeMenu,
              delivery_name,
              delivery_address,
              delivery_city,
              delivery_county,
              delivery_country,
              delivery_email,
              delivery_phone,
              selectContentDeliveryName,
              selectContent,
              selectContentDeliveryAddress,
              selectContentDeliveryCity,
              selectContentDeliveryCounty,
              selectContentDeliveryCountry,
              selectContentDeliveryPostalCode,
              selectContentDeliveryEmail,
              selectContentDeliveryPhone,
              // trimiteComandaPrepaid,
              total_cost_package_and_trafic,
              openBT,
              check_human,
              close,
              showPopup,
              confirm_prepaid_command,
              formUrl,
              show_section_footer_desktop,
              show_section_footer_mobile,
              transport_cost,
              euro,
              acceptCookies,
              showMainSection,
            showCeleMaiAccesibile,
              showMenu,
              closeMainSections,
              show_section_recharge_mobile,
              show_section_recharge_desktop,
              show_topup_cc_mobile,
            showTopMenuRecharge,
              selectedRechargeAmount,
              enteredMsisdn,
              cancel_recharge_by_cc,
              recharge_by_cc,
              topup_voucher_front_page_msisdn,
              topup_voucher_front_page_voucher,
              cancel_recharge_by_voucher,
              recharge_by_voucher,
              enteredEmail,
              is_admin,
              call_recharge_by_cc,
              confirm_recharge_by_cc,
              recharging_values,
              cost_postpaid_sim,
              cost_postpaid_abonament_sim,
              tarif_date_postpaid,
              abonament_postpaid,
              minim_contractual_period,
              tarif_sms_postpaid,
              nr_of_postpaid_sims,
            checkPostpaidMinMaxNrOfSims,
            checkPrepaidMinMaxNrOfSims,
              tarif_date_prepaid,
              confirm_postpaid_command,
              contact_name,
              contact_email,
              contact_phone,
              contact_text,
              cancel_contact_form,
              send_contact_form,
              customFormatter,
              login_under_construction,
              siteKey,
              handleError,
              handleSuccess,
              totalAmountPostpaid,
              discountPostpaid,
              costPostpaidSubscription,
              recaptcha,
              recaptchaContact,
              recaptchaSendCommand,
              VueReCaptcha,
              showRechargingOptionPrepaid,
              totalCostSubscriptionPostpaid,
              debug,
              changeLanguage,
              languagePopoverOpen,
              t,
              openLanguagePopover,
              openLanguageMobilePopover,
              roSelected,
              enSelected,
              bgSelected,
              geSelected,
              frSelected,
              spSelected,
              itSelected,
              ruSelected,
              cnSelected,
              trSelected,
              IonPopover,
            goToPage,
            openMaineMenu,
            menuOutline,
            useBackButton

          }
        }
      }
  </script>

<style scoped>

.m2mValability th {
    background: white;
  border: 1px solid grey;
  }

.m2mValability tr:nth-child(odd) {
    background: white;
    text-align: center;
    padding: 5px 5px;
  border: 1px solid grey;
  }

.m2mValability  tr:nth-child(even) {
    background: #fcb201;
  }
.m2mValability td {
  font-size: 16px;
  border: 1px solid grey;
  }

.m2mValability   th tr td {
  font-size: 10px;
  border: 1px solid grey;
  }

.m2mValability {
  border: 1px solid grey;
}


ion-button{
    //background-color: #fbb21d;
    //color: white;
    //width:220px;
    //height:40px;
    //vertical-align: center;
    //font-size: 21px;
    //font-family:"Helvetica Neue LT Pro 67 Medium Condensed";
    //font-weight: 500;
    //padding-top:2px;
    //border-width: 0px !important;
    //box-shadow: 3px 3px grey;
    //border-radius: 2px;
}
ion-menu-button {
  color: black;
}
ion-input{
  border-bottom: 1px solid grey;
  text-align: right;
}
.subtitluCartelaNextM2m{
  font-size: 22px;
  font-weight: 600
}
.font24{
  font-size: 24px;
}

.siglaMobile{
  width: 50px;
}

.langFlag{
  width: 20px;
  height: 20px;
}
.langMobileFlag{
  width: 30px;
  height: 30px;
}
.siglaDesktop{
  width: 80px;
}

.relativeLeft30{
  position: relative;
  left:-30px;
}
.btnWidth100{
  width:100px;
}

ion-select {
  width: 100%;
  min-width: 10ch;
  max-width: 30ch;
  border: 1px solid var(--select-border);
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  font-size: 1.1rem;
  cursor: pointer;
  line-height: 1.1;
  background: linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
}

</style>
