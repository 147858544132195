const gtmScript = document.createElement('script');
gtmScript.async = true;
gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=G-Y7N293M0HS`;
document.head.appendChild(gtmScript);
setTimeout(()=>{
    gtmScript.addEventListener('load', () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'G-Y7N293M0HS');
});
},200)
import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store/index.js'
import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
import './assets/main.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
/* Theme variables */
import './theme/variables.css';
import './assets/general.css';
// import VueGoogleMaps from "vue-google-maps-community-fork"

// const versionCode=48
// const versionName='1.1.0'
// import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { VueReCaptcha } from "vue-recaptcha-v3";
// defineCustomElements(window);
// const app = createApp({
//     render: h => h(App),
//     beforeMount() {
//         this.OneSignal.init({
//             appId: "50accc6c-1519-44eb-96cb-654b618da359",
//             allowLocalhostAsSecureOrigin: true,
//         });
//     },
// })

const app = createApp(App)
  .use(IonicVue)
  .use(store)
  .use(router)
  .use(VueReCaptcha, { siteKey: "6Le1RdQUAAAAAM22byaAYDeHEkvI6A23xCQwFNet" })

app.config.globalProperties.API_URL ="https://api.rcharge.ro/api/v1/home/";

router.isReady().then(() => {
    app.mount('#app');
});
