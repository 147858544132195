import { ref } from 'vue';
import {log} from "@/composables/useLog";
const t=ref (null)
const languagePopoverOpen=ref (false)
const languagePopoverMobileOpen=ref (false)
const roSelected=ref (true)
const enSelected=ref (false)
const bgSelected=ref (false)
const geSelected=ref (false)
const frSelected=ref (false)
const spSelected=ref (false)
const itSelected=ref (false)
const ruSelected=ref (false)
const cnSelected=ref (false)
const trSelected=ref (false)
const huSelected=ref (false)

function changeLanguage(lang) {
    log("imported "+lang)
    translation(lang)
    log("am schimbat limba in:")
    log(t.value.country_code)
    languagePopoverOpen.value=false
    languagePopoverMobileOpen.value=false
}

function openLanguagePopover() {
    languagePopoverOpen.value=true
}
function openLanguageMobilePopover() {
    languagePopoverMobileOpen.value=true
}

const messages= {
    ro: require('@/locales/ro.json'),
    en: require('@/locales/en.json'),
    sp: require('@/locales/sp.json'),
    fr: require('@/locales/fr.json'),
    ge: require('@/locales/ge.json'),
    bg: require('@/locales/bg.json'),
    ru: require('@/locales/ru.json'),
    it: require('@/locales/it.json'),
    tr: require('@/locales/tr.json'),
    hu: require('@/locales/hu.json'),
    ci: require('@/locales/ci.json'),
}

t.value= messages.ro

const translation=(lang)=>{
    unselectFlags().then(()=>{
        if(lang=='en'){
            log(1)
            t.value= messages.en
            enSelected.value=true
        }
        else if(lang=='ro'){
            log(2)
            t.value= messages.ro
            roSelected.value=true
        }
        else if(lang=='ru'){
            t.value= messages.ru
            ruSelected.value=true
        }
        else if(lang=='fr'){
            t.value= messages.fr
            frSelected.value=true
        }
        else if(lang=='sp'){
            t.value= messages.sp
            spSelected.value=true
        }
        else if(lang=='it'){
            t.value= messages.it
            itSelected.value=true
        }
        else if(lang=='bg'){
            t.value= messages.bg
            bgSelected.value=true
        }
        else if(lang=='hu'){
            t.value= messages.hu
            huSelected.value=true
        }
        else if(lang=='tr'){
            t.value= messages.tr
            trSelected.value=true
        }
        else if(lang=='ge'){
            t.value= messages.ge
            geSelected.value=true
        }
        else if(lang=='cn'){
            t.value= messages.ci
            cnSelected.value=true
        }
        else{
            t.value= messages.en
        }
    })
}

const unselectFlags = async () => {
    roSelected.value=false
    enSelected.value=false
    bgSelected.value=false
    geSelected.value=false
    frSelected.value=false
    spSelected.value=false
    itSelected.value=false
    ruSelected.value=false
    cnSelected.value=false
    trSelected.value=false
    huSelected.value=false
}

export {
    changeLanguage,
    languagePopoverOpen,
    t,
    openLanguagePopover,
    openLanguageMobilePopover,
    languagePopoverMobileOpen,
    roSelected,
    enSelected,
    bgSelected,
    geSelected,
    frSelected,
    spSelected,
    itSelected,
    ruSelected,
    cnSelected,
    trSelected,
}

